:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #7952b3;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #333;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: .875em;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .875em;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.btn {
  color: #333;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .4rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px #00000020;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 .2rem #007bff40, inset 0 3px 5px #00000020;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background: #007bff linear-gradient(#268fff, #007bff) repeat-x;
  border-color: #007bff;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-primary:hover {
  color: #fff;
  background: #0069d9 linear-gradient(#267fde, #0069d9) repeat-x;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background: #0069d9 linear-gradient(#267fde, #0069d9) repeat-x;
  border-color: #0062cc;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #268fff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  background-image: none;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  background-image: none;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #268fff80;
}

.btn-secondary {
  color: #fff;
  background: #6c757d linear-gradient(#828a91, #6c757d) repeat-x;
  border-color: #6c757d;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-secondary:hover {
  color: #fff;
  background: #5a6268 linear-gradient(#73797f, #5a6268) repeat-x;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background: #5a6268 linear-gradient(#73797f, #5a6268) repeat-x;
  border-color: #545b62;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  background-image: none;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  background-image: none;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background: #7952b3 linear-gradient(#8d6cbe, #7952b3) repeat-x;
  border-color: #7952b3;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-success:hover {
  color: #fff;
  background: #67449b linear-gradient(#7e60aa, #67449b) repeat-x;
  border-color: #614092;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background: #67449b linear-gradient(#7e60aa, #67449b) repeat-x;
  border-color: #614092;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #8d6cbe80;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #7952b3;
  background-image: none;
  border-color: #7952b3;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #614092;
  background-image: none;
  border-color: #5b3c89;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #8d6cbe80;
}

.btn-info {
  color: #fff;
  background: #17a2b8 linear-gradient(#3ab0c3, #17a2b8) repeat-x;
  border-color: #17a2b8;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-info:hover {
  color: #fff;
  background: #138496 linear-gradient(#3697a6, #138496) repeat-x;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background: #138496 linear-gradient(#3697a6, #138496) repeat-x;
  border-color: #117a8b;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  background-image: none;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  background-image: none;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background: #ffc107 linear-gradient(#ffca2c, #ffc107) repeat-x;
  border-color: #ffc107;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-warning:hover {
  color: #212529;
  background: #e0a800 linear-gradient(#e4b526, #e0a800) repeat-x;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background: #e0a800 linear-gradient(#e4b526, #e0a800) repeat-x;
  border-color: #d39e00;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  background-image: none;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  background-image: none;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background: #dc3545 linear-gradient(#e15361, #dc3545) repeat-x;
  border-color: #dc3545;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-danger:hover {
  color: #fff;
  background: #c82333 linear-gradient(#d04451, #c82333) repeat-x;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background: #c82333 linear-gradient(#d04451, #c82333) repeat-x;
  border-color: #bd2130;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  background-image: none;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  background-image: none;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background: #f8f9fa linear-gradient(#f9fafb, #f8f9fa) repeat-x;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-light:hover {
  color: #212529;
  background: #e2e6ea linear-gradient(#e6eaed, #e2e6ea) repeat-x;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background: #e2e6ea linear-gradient(#e6eaed, #e2e6ea) repeat-x;
  border-color: #dae0e5;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  background-image: none;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background: #343a40 linear-gradient(#52585d, #343a40) repeat-x;
  border-color: #343a40;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-dark:hover {
  color: #fff;
  background: #23272b linear-gradient(#44474b, #23272b) repeat-x;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background: #23272b linear-gradient(#44474b, #23272b) repeat-x;
  border-color: #1d2124;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013, 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  background-image: none;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  background-image: none;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #7952b3;
  border-color: #7952b3;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #7952b3;
  border-color: #7952b3;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #7952b380;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #7952b3;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #7952b3;
  border-color: #7952b3;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #7952b380;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px #00000020, 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
  box-shadow: 0 .25rem .5rem #00000080;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -moz-min-content;
    height: min-content;
  }

  .modal-content {
    box-shadow: 0 .5rem 1rem #00000080;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #7952b3 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #614092 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-gradient-primary {
  background: #007bff linear-gradient(#268fff, #007bff) repeat-x !important;
}

.bg-gradient-secondary {
  background: #6c757d linear-gradient(#828a91, #6c757d) repeat-x !important;
}

.bg-gradient-success {
  background: #7952b3 linear-gradient(#8d6cbe, #7952b3) repeat-x !important;
}

.bg-gradient-info {
  background: #17a2b8 linear-gradient(#3ab0c3, #17a2b8) repeat-x !important;
}

.bg-gradient-warning {
  background: #ffc107 linear-gradient(#ffca2c, #ffc107) repeat-x !important;
}

.bg-gradient-danger {
  background: #dc3545 linear-gradient(#e15361, #dc3545) repeat-x !important;
}

.bg-gradient-light {
  background: #f8f9fa linear-gradient(#f9fafb, #f8f9fa) repeat-x !important;
}

.bg-gradient-dark {
  background: #343a40 linear-gradient(#52585d, #343a40) repeat-x !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #7952b3 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .4rem !important;
}

.rounded-top {
  border-top-left-radius: .4rem !important;
  border-top-right-radius: .4rem !important;
}

.rounded-right {
  border-top-right-radius: .4rem !important;
  border-bottom-right-radius: .4rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .4rem !important;
  border-bottom-left-radius: .4rem !important;
}

.rounded-left {
  border-top-left-radius: .4rem !important;
  border-bottom-left-radius: .4rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: sticky) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #7952b3 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #553880 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #333 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.icon-list {
  padding-left: 0;
  list-style: none;
}

.icon-list li {
  align-items: flex-start;
  margin-bottom: .25rem;
  display: flex;
}

.icon-list li:before {
  width: 1.5em;
  height: 1.5em;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") center / 100% no-repeat;
  flex-shrink: 0;
  margin-right: .5rem;
  display: block;
}

body {
  padding: 3rem 1.5rem;
}

.bi {
  fill: currentColor;
}

@font-face {
  font-display: block;
  font-family: bootstrap-icons;
  src: url("bootstrap-icons.d0645dd2.woff2") format("woff2"), url("bootstrap-icons.92e34d03.woff") format("woff");
}

.bi:before, [class^="bi-"]:before, [class*=" bi-"]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-weight: normal !important;
}

.bi-123:before {
  content: "";
}

.bi-alarm-fill:before {
  content: "";
}

.bi-alarm:before {
  content: "";
}

.bi-align-bottom:before {
  content: "";
}

.bi-align-center:before {
  content: "";
}

.bi-align-end:before {
  content: "";
}

.bi-align-middle:before {
  content: "";
}

.bi-align-start:before {
  content: "";
}

.bi-align-top:before {
  content: "";
}

.bi-alt:before {
  content: "";
}

.bi-app-indicator:before {
  content: "";
}

.bi-app:before {
  content: "";
}

.bi-archive-fill:before {
  content: "";
}

.bi-archive:before {
  content: "";
}

.bi-arrow-90deg-down:before {
  content: "";
}

.bi-arrow-90deg-left:before {
  content: "";
}

.bi-arrow-90deg-right:before {
  content: "";
}

.bi-arrow-90deg-up:before {
  content: "";
}

.bi-arrow-bar-down:before {
  content: "";
}

.bi-arrow-bar-left:before {
  content: "";
}

.bi-arrow-bar-right:before {
  content: "";
}

.bi-arrow-bar-up:before {
  content: "";
}

.bi-arrow-clockwise:before {
  content: "";
}

.bi-arrow-counterclockwise:before {
  content: "";
}

.bi-arrow-down-circle-fill:before {
  content: "";
}

.bi-arrow-down-circle:before {
  content: "";
}

.bi-arrow-down-left-circle-fill:before {
  content: "";
}

.bi-arrow-down-left-circle:before {
  content: "";
}

.bi-arrow-down-left-square-fill:before {
  content: "";
}

.bi-arrow-down-left-square:before {
  content: "";
}

.bi-arrow-down-left:before {
  content: "";
}

.bi-arrow-down-right-circle-fill:before {
  content: "";
}

.bi-arrow-down-right-circle:before {
  content: "";
}

.bi-arrow-down-right-square-fill:before {
  content: "";
}

.bi-arrow-down-right-square:before {
  content: "";
}

.bi-arrow-down-right:before {
  content: "";
}

.bi-arrow-down-short:before {
  content: "";
}

.bi-arrow-down-square-fill:before {
  content: "";
}

.bi-arrow-down-square:before {
  content: "";
}

.bi-arrow-down-up:before {
  content: "";
}

.bi-arrow-down:before {
  content: "";
}

.bi-arrow-left-circle-fill:before {
  content: "";
}

.bi-arrow-left-circle:before {
  content: "";
}

.bi-arrow-left-right:before {
  content: "";
}

.bi-arrow-left-short:before {
  content: "";
}

.bi-arrow-left-square-fill:before {
  content: "";
}

.bi-arrow-left-square:before {
  content: "";
}

.bi-arrow-left:before {
  content: "";
}

.bi-arrow-repeat:before {
  content: "";
}

.bi-arrow-return-left:before {
  content: "";
}

.bi-arrow-return-right:before {
  content: "";
}

.bi-arrow-right-circle-fill:before {
  content: "";
}

.bi-arrow-right-circle:before {
  content: "";
}

.bi-arrow-right-short:before {
  content: "";
}

.bi-arrow-right-square-fill:before {
  content: "";
}

.bi-arrow-right-square:before {
  content: "";
}

.bi-arrow-right:before {
  content: "";
}

.bi-arrow-up-circle-fill:before {
  content: "";
}

.bi-arrow-up-circle:before {
  content: "";
}

.bi-arrow-up-left-circle-fill:before {
  content: "";
}

.bi-arrow-up-left-circle:before {
  content: "";
}

.bi-arrow-up-left-square-fill:before {
  content: "";
}

.bi-arrow-up-left-square:before {
  content: "";
}

.bi-arrow-up-left:before {
  content: "";
}

.bi-arrow-up-right-circle-fill:before {
  content: "";
}

.bi-arrow-up-right-circle:before {
  content: "";
}

.bi-arrow-up-right-square-fill:before {
  content: "";
}

.bi-arrow-up-right-square:before {
  content: "";
}

.bi-arrow-up-right:before {
  content: "";
}

.bi-arrow-up-short:before {
  content: "";
}

.bi-arrow-up-square-fill:before {
  content: "";
}

.bi-arrow-up-square:before {
  content: "";
}

.bi-arrow-up:before {
  content: "";
}

.bi-arrows-angle-contract:before {
  content: "";
}

.bi-arrows-angle-expand:before {
  content: "";
}

.bi-arrows-collapse:before {
  content: "";
}

.bi-arrows-expand:before {
  content: "";
}

.bi-arrows-fullscreen:before {
  content: "";
}

.bi-arrows-move:before {
  content: "";
}

.bi-aspect-ratio-fill:before {
  content: "";
}

.bi-aspect-ratio:before {
  content: "";
}

.bi-asterisk:before {
  content: "";
}

.bi-at:before {
  content: "";
}

.bi-award-fill:before {
  content: "";
}

.bi-award:before {
  content: "";
}

.bi-back:before {
  content: "";
}

.bi-backspace-fill:before {
  content: "";
}

.bi-backspace-reverse-fill:before {
  content: "";
}

.bi-backspace-reverse:before {
  content: "";
}

.bi-backspace:before {
  content: "";
}

.bi-badge-3d-fill:before {
  content: "";
}

.bi-badge-3d:before {
  content: "";
}

.bi-badge-4k-fill:before {
  content: "";
}

.bi-badge-4k:before {
  content: "";
}

.bi-badge-8k-fill:before {
  content: "";
}

.bi-badge-8k:before {
  content: "";
}

.bi-badge-ad-fill:before {
  content: "";
}

.bi-badge-ad:before {
  content: "";
}

.bi-badge-ar-fill:before {
  content: "";
}

.bi-badge-ar:before {
  content: "";
}

.bi-badge-cc-fill:before {
  content: "";
}

.bi-badge-cc:before {
  content: "";
}

.bi-badge-hd-fill:before {
  content: "";
}

.bi-badge-hd:before {
  content: "";
}

.bi-badge-tm-fill:before {
  content: "";
}

.bi-badge-tm:before {
  content: "";
}

.bi-badge-vo-fill:before {
  content: "";
}

.bi-badge-vo:before {
  content: "";
}

.bi-badge-vr-fill:before {
  content: "";
}

.bi-badge-vr:before {
  content: "";
}

.bi-badge-wc-fill:before {
  content: "";
}

.bi-badge-wc:before {
  content: "";
}

.bi-bag-check-fill:before {
  content: "";
}

.bi-bag-check:before {
  content: "";
}

.bi-bag-dash-fill:before {
  content: "";
}

.bi-bag-dash:before {
  content: "";
}

.bi-bag-fill:before {
  content: "";
}

.bi-bag-plus-fill:before {
  content: "";
}

.bi-bag-plus:before {
  content: "";
}

.bi-bag-x-fill:before {
  content: "";
}

.bi-bag-x:before {
  content: "";
}

.bi-bag:before {
  content: "";
}

.bi-bar-chart-fill:before {
  content: "";
}

.bi-bar-chart-line-fill:before {
  content: "";
}

.bi-bar-chart-line:before {
  content: "";
}

.bi-bar-chart-steps:before {
  content: "";
}

.bi-bar-chart:before {
  content: "";
}

.bi-basket-fill:before {
  content: "";
}

.bi-basket:before {
  content: "";
}

.bi-basket2-fill:before {
  content: "";
}

.bi-basket2:before {
  content: "";
}

.bi-basket3-fill:before {
  content: "";
}

.bi-basket3:before {
  content: "";
}

.bi-battery-charging:before {
  content: "";
}

.bi-battery-full:before {
  content: "";
}

.bi-battery-half:before {
  content: "";
}

.bi-battery:before {
  content: "";
}

.bi-bell-fill:before {
  content: "";
}

.bi-bell:before {
  content: "";
}

.bi-bezier:before {
  content: "";
}

.bi-bezier2:before {
  content: "";
}

.bi-bicycle:before {
  content: "";
}

.bi-binoculars-fill:before {
  content: "";
}

.bi-binoculars:before {
  content: "";
}

.bi-blockquote-left:before {
  content: "";
}

.bi-blockquote-right:before {
  content: "";
}

.bi-book-fill:before {
  content: "";
}

.bi-book-half:before {
  content: "";
}

.bi-book:before {
  content: "";
}

.bi-bookmark-check-fill:before {
  content: "";
}

.bi-bookmark-check:before {
  content: "";
}

.bi-bookmark-dash-fill:before {
  content: "";
}

.bi-bookmark-dash:before {
  content: "";
}

.bi-bookmark-fill:before {
  content: "";
}

.bi-bookmark-heart-fill:before {
  content: "";
}

.bi-bookmark-heart:before {
  content: "";
}

.bi-bookmark-plus-fill:before {
  content: "";
}

.bi-bookmark-plus:before {
  content: "";
}

.bi-bookmark-star-fill:before {
  content: "";
}

.bi-bookmark-star:before {
  content: "";
}

.bi-bookmark-x-fill:before {
  content: "";
}

.bi-bookmark-x:before {
  content: "";
}

.bi-bookmark:before {
  content: "";
}

.bi-bookmarks-fill:before {
  content: "";
}

.bi-bookmarks:before {
  content: "";
}

.bi-bookshelf:before {
  content: "";
}

.bi-bootstrap-fill:before {
  content: "";
}

.bi-bootstrap-reboot:before {
  content: "";
}

.bi-bootstrap:before {
  content: "";
}

.bi-border-all:before {
  content: "";
}

.bi-border-bottom:before {
  content: "";
}

.bi-border-center:before {
  content: "";
}

.bi-border-inner:before {
  content: "";
}

.bi-border-left:before {
  content: "";
}

.bi-border-middle:before {
  content: "";
}

.bi-border-outer:before {
  content: "";
}

.bi-border-right:before {
  content: "";
}

.bi-border-style:before {
  content: "";
}

.bi-border-top:before {
  content: "";
}

.bi-border-width:before {
  content: "";
}

.bi-border:before {
  content: "";
}

.bi-bounding-box-circles:before {
  content: "";
}

.bi-bounding-box:before {
  content: "";
}

.bi-box-arrow-down-left:before {
  content: "";
}

.bi-box-arrow-down-right:before {
  content: "";
}

.bi-box-arrow-down:before {
  content: "";
}

.bi-box-arrow-in-down-left:before {
  content: "";
}

.bi-box-arrow-in-down-right:before {
  content: "";
}

.bi-box-arrow-in-down:before {
  content: "";
}

.bi-box-arrow-in-left:before {
  content: "";
}

.bi-box-arrow-in-right:before {
  content: "";
}

.bi-box-arrow-in-up-left:before {
  content: "";
}

.bi-box-arrow-in-up-right:before {
  content: "";
}

.bi-box-arrow-in-up:before {
  content: "";
}

.bi-box-arrow-left:before {
  content: "";
}

.bi-box-arrow-right:before {
  content: "";
}

.bi-box-arrow-up-left:before {
  content: "";
}

.bi-box-arrow-up-right:before {
  content: "";
}

.bi-box-arrow-up:before {
  content: "";
}

.bi-box-seam:before {
  content: "";
}

.bi-box:before {
  content: "";
}

.bi-braces:before {
  content: "";
}

.bi-bricks:before {
  content: "";
}

.bi-briefcase-fill:before {
  content: "";
}

.bi-briefcase:before {
  content: "";
}

.bi-brightness-alt-high-fill:before {
  content: "";
}

.bi-brightness-alt-high:before {
  content: "";
}

.bi-brightness-alt-low-fill:before {
  content: "";
}

.bi-brightness-alt-low:before {
  content: "";
}

.bi-brightness-high-fill:before {
  content: "";
}

.bi-brightness-high:before {
  content: "";
}

.bi-brightness-low-fill:before {
  content: "";
}

.bi-brightness-low:before {
  content: "";
}

.bi-broadcast-pin:before {
  content: "";
}

.bi-broadcast:before {
  content: "";
}

.bi-brush-fill:before {
  content: "";
}

.bi-brush:before {
  content: "";
}

.bi-bucket-fill:before {
  content: "";
}

.bi-bucket:before {
  content: "";
}

.bi-bug-fill:before {
  content: "";
}

.bi-bug:before {
  content: "";
}

.bi-building:before {
  content: "";
}

.bi-bullseye:before {
  content: "";
}

.bi-calculator-fill:before {
  content: "";
}

.bi-calculator:before {
  content: "";
}

.bi-calendar-check-fill:before {
  content: "";
}

.bi-calendar-check:before {
  content: "";
}

.bi-calendar-date-fill:before {
  content: "";
}

.bi-calendar-date:before {
  content: "";
}

.bi-calendar-day-fill:before {
  content: "";
}

.bi-calendar-day:before {
  content: "";
}

.bi-calendar-event-fill:before {
  content: "";
}

.bi-calendar-event:before {
  content: "";
}

.bi-calendar-fill:before {
  content: "";
}

.bi-calendar-minus-fill:before {
  content: "";
}

.bi-calendar-minus:before {
  content: "";
}

.bi-calendar-month-fill:before {
  content: "";
}

.bi-calendar-month:before {
  content: "";
}

.bi-calendar-plus-fill:before {
  content: "";
}

.bi-calendar-plus:before {
  content: "";
}

.bi-calendar-range-fill:before {
  content: "";
}

.bi-calendar-range:before {
  content: "";
}

.bi-calendar-week-fill:before {
  content: "";
}

.bi-calendar-week:before {
  content: "";
}

.bi-calendar-x-fill:before {
  content: "";
}

.bi-calendar-x:before {
  content: "";
}

.bi-calendar:before {
  content: "";
}

.bi-calendar2-check-fill:before {
  content: "";
}

.bi-calendar2-check:before {
  content: "";
}

.bi-calendar2-date-fill:before {
  content: "";
}

.bi-calendar2-date:before {
  content: "";
}

.bi-calendar2-day-fill:before {
  content: "";
}

.bi-calendar2-day:before {
  content: "";
}

.bi-calendar2-event-fill:before {
  content: "";
}

.bi-calendar2-event:before {
  content: "";
}

.bi-calendar2-fill:before {
  content: "";
}

.bi-calendar2-minus-fill:before {
  content: "";
}

.bi-calendar2-minus:before {
  content: "";
}

.bi-calendar2-month-fill:before {
  content: "";
}

.bi-calendar2-month:before {
  content: "";
}

.bi-calendar2-plus-fill:before {
  content: "";
}

.bi-calendar2-plus:before {
  content: "";
}

.bi-calendar2-range-fill:before {
  content: "";
}

.bi-calendar2-range:before {
  content: "";
}

.bi-calendar2-week-fill:before {
  content: "";
}

.bi-calendar2-week:before {
  content: "";
}

.bi-calendar2-x-fill:before {
  content: "";
}

.bi-calendar2-x:before {
  content: "";
}

.bi-calendar2:before {
  content: "";
}

.bi-calendar3-event-fill:before {
  content: "";
}

.bi-calendar3-event:before {
  content: "";
}

.bi-calendar3-fill:before {
  content: "";
}

.bi-calendar3-range-fill:before {
  content: "";
}

.bi-calendar3-range:before {
  content: "";
}

.bi-calendar3-week-fill:before {
  content: "";
}

.bi-calendar3-week:before {
  content: "";
}

.bi-calendar3:before {
  content: "";
}

.bi-calendar4-event:before {
  content: "";
}

.bi-calendar4-range:before {
  content: "";
}

.bi-calendar4-week:before {
  content: "";
}

.bi-calendar4:before {
  content: "";
}

.bi-camera-fill:before {
  content: "";
}

.bi-camera-reels-fill:before {
  content: "";
}

.bi-camera-reels:before {
  content: "";
}

.bi-camera-video-fill:before {
  content: "";
}

.bi-camera-video-off-fill:before {
  content: "";
}

.bi-camera-video-off:before {
  content: "";
}

.bi-camera-video:before {
  content: "";
}

.bi-camera:before {
  content: "";
}

.bi-camera2:before {
  content: "";
}

.bi-capslock-fill:before {
  content: "";
}

.bi-capslock:before {
  content: "";
}

.bi-card-checklist:before {
  content: "";
}

.bi-card-heading:before {
  content: "";
}

.bi-card-image:before {
  content: "";
}

.bi-card-list:before {
  content: "";
}

.bi-card-text:before {
  content: "";
}

.bi-caret-down-fill:before {
  content: "";
}

.bi-caret-down-square-fill:before {
  content: "";
}

.bi-caret-down-square:before {
  content: "";
}

.bi-caret-down:before {
  content: "";
}

.bi-caret-left-fill:before {
  content: "";
}

.bi-caret-left-square-fill:before {
  content: "";
}

.bi-caret-left-square:before {
  content: "";
}

.bi-caret-left:before {
  content: "";
}

.bi-caret-right-fill:before {
  content: "";
}

.bi-caret-right-square-fill:before {
  content: "";
}

.bi-caret-right-square:before {
  content: "";
}

.bi-caret-right:before {
  content: "";
}

.bi-caret-up-fill:before {
  content: "";
}

.bi-caret-up-square-fill:before {
  content: "";
}

.bi-caret-up-square:before {
  content: "";
}

.bi-caret-up:before {
  content: "";
}

.bi-cart-check-fill:before {
  content: "";
}

.bi-cart-check:before {
  content: "";
}

.bi-cart-dash-fill:before {
  content: "";
}

.bi-cart-dash:before {
  content: "";
}

.bi-cart-fill:before {
  content: "";
}

.bi-cart-plus-fill:before {
  content: "";
}

.bi-cart-plus:before {
  content: "";
}

.bi-cart-x-fill:before {
  content: "";
}

.bi-cart-x:before {
  content: "";
}

.bi-cart:before {
  content: "";
}

.bi-cart2:before {
  content: "";
}

.bi-cart3:before {
  content: "";
}

.bi-cart4:before {
  content: "";
}

.bi-cash-stack:before {
  content: "";
}

.bi-cash:before {
  content: "";
}

.bi-cast:before {
  content: "";
}

.bi-chat-dots-fill:before {
  content: "";
}

.bi-chat-dots:before {
  content: "";
}

.bi-chat-fill:before {
  content: "";
}

.bi-chat-left-dots-fill:before {
  content: "";
}

.bi-chat-left-dots:before {
  content: "";
}

.bi-chat-left-fill:before {
  content: "";
}

.bi-chat-left-quote-fill:before {
  content: "";
}

.bi-chat-left-quote:before {
  content: "";
}

.bi-chat-left-text-fill:before {
  content: "";
}

.bi-chat-left-text:before {
  content: "";
}

.bi-chat-left:before {
  content: "";
}

.bi-chat-quote-fill:before {
  content: "";
}

.bi-chat-quote:before {
  content: "";
}

.bi-chat-right-dots-fill:before {
  content: "";
}

.bi-chat-right-dots:before {
  content: "";
}

.bi-chat-right-fill:before {
  content: "";
}

.bi-chat-right-quote-fill:before {
  content: "";
}

.bi-chat-right-quote:before {
  content: "";
}

.bi-chat-right-text-fill:before {
  content: "";
}

.bi-chat-right-text:before {
  content: "";
}

.bi-chat-right:before {
  content: "";
}

.bi-chat-square-dots-fill:before {
  content: "";
}

.bi-chat-square-dots:before {
  content: "";
}

.bi-chat-square-fill:before {
  content: "";
}

.bi-chat-square-quote-fill:before {
  content: "";
}

.bi-chat-square-quote:before {
  content: "";
}

.bi-chat-square-text-fill:before {
  content: "";
}

.bi-chat-square-text:before {
  content: "";
}

.bi-chat-square:before {
  content: "";
}

.bi-chat-text-fill:before {
  content: "";
}

.bi-chat-text:before {
  content: "";
}

.bi-chat:before {
  content: "";
}

.bi-check-all:before {
  content: "";
}

.bi-check-circle-fill:before {
  content: "";
}

.bi-check-circle:before {
  content: "";
}

.bi-check-square-fill:before {
  content: "";
}

.bi-check-square:before {
  content: "";
}

.bi-check:before {
  content: "";
}

.bi-check2-all:before {
  content: "";
}

.bi-check2-circle:before {
  content: "";
}

.bi-check2-square:before {
  content: "";
}

.bi-check2:before {
  content: "";
}

.bi-chevron-bar-contract:before {
  content: "";
}

.bi-chevron-bar-down:before {
  content: "";
}

.bi-chevron-bar-expand:before {
  content: "";
}

.bi-chevron-bar-left:before {
  content: "";
}

.bi-chevron-bar-right:before {
  content: "";
}

.bi-chevron-bar-up:before {
  content: "";
}

.bi-chevron-compact-down:before {
  content: "";
}

.bi-chevron-compact-left:before {
  content: "";
}

.bi-chevron-compact-right:before {
  content: "";
}

.bi-chevron-compact-up:before {
  content: "";
}

.bi-chevron-contract:before {
  content: "";
}

.bi-chevron-double-down:before {
  content: "";
}

.bi-chevron-double-left:before {
  content: "";
}

.bi-chevron-double-right:before {
  content: "";
}

.bi-chevron-double-up:before {
  content: "";
}

.bi-chevron-down:before {
  content: "";
}

.bi-chevron-expand:before {
  content: "";
}

.bi-chevron-left:before {
  content: "";
}

.bi-chevron-right:before {
  content: "";
}

.bi-chevron-up:before {
  content: "";
}

.bi-circle-fill:before {
  content: "";
}

.bi-circle-half:before {
  content: "";
}

.bi-circle-square:before {
  content: "";
}

.bi-circle:before {
  content: "";
}

.bi-clipboard-check:before {
  content: "";
}

.bi-clipboard-data:before {
  content: "";
}

.bi-clipboard-minus:before {
  content: "";
}

.bi-clipboard-plus:before {
  content: "";
}

.bi-clipboard-x:before {
  content: "";
}

.bi-clipboard:before {
  content: "";
}

.bi-clock-fill:before {
  content: "";
}

.bi-clock-history:before {
  content: "";
}

.bi-clock:before {
  content: "";
}

.bi-cloud-arrow-down-fill:before {
  content: "";
}

.bi-cloud-arrow-down:before {
  content: "";
}

.bi-cloud-arrow-up-fill:before {
  content: "";
}

.bi-cloud-arrow-up:before {
  content: "";
}

.bi-cloud-check-fill:before {
  content: "";
}

.bi-cloud-check:before {
  content: "";
}

.bi-cloud-download-fill:before {
  content: "";
}

.bi-cloud-download:before {
  content: "";
}

.bi-cloud-drizzle-fill:before {
  content: "";
}

.bi-cloud-drizzle:before {
  content: "";
}

.bi-cloud-fill:before {
  content: "";
}

.bi-cloud-fog-fill:before {
  content: "";
}

.bi-cloud-fog:before {
  content: "";
}

.bi-cloud-fog2-fill:before {
  content: "";
}

.bi-cloud-fog2:before {
  content: "";
}

.bi-cloud-hail-fill:before {
  content: "";
}

.bi-cloud-hail:before {
  content: "";
}

.bi-cloud-haze-1:before {
  content: "";
}

.bi-cloud-haze-fill:before {
  content: "";
}

.bi-cloud-haze:before {
  content: "";
}

.bi-cloud-haze2-fill:before {
  content: "";
}

.bi-cloud-lightning-fill:before {
  content: "";
}

.bi-cloud-lightning-rain-fill:before {
  content: "";
}

.bi-cloud-lightning-rain:before {
  content: "";
}

.bi-cloud-lightning:before {
  content: "";
}

.bi-cloud-minus-fill:before {
  content: "";
}

.bi-cloud-minus:before {
  content: "";
}

.bi-cloud-moon-fill:before {
  content: "";
}

.bi-cloud-moon:before {
  content: "";
}

.bi-cloud-plus-fill:before {
  content: "";
}

.bi-cloud-plus:before {
  content: "";
}

.bi-cloud-rain-fill:before {
  content: "";
}

.bi-cloud-rain-heavy-fill:before {
  content: "";
}

.bi-cloud-rain-heavy:before {
  content: "";
}

.bi-cloud-rain:before {
  content: "";
}

.bi-cloud-slash-fill:before {
  content: "";
}

.bi-cloud-slash:before {
  content: "";
}

.bi-cloud-sleet-fill:before {
  content: "";
}

.bi-cloud-sleet:before {
  content: "";
}

.bi-cloud-snow-fill:before {
  content: "";
}

.bi-cloud-snow:before {
  content: "";
}

.bi-cloud-sun-fill:before {
  content: "";
}

.bi-cloud-sun:before {
  content: "";
}

.bi-cloud-upload-fill:before {
  content: "";
}

.bi-cloud-upload:before {
  content: "";
}

.bi-cloud:before {
  content: "";
}

.bi-clouds-fill:before {
  content: "";
}

.bi-clouds:before {
  content: "";
}

.bi-cloudy-fill:before {
  content: "";
}

.bi-cloudy:before {
  content: "";
}

.bi-code-slash:before {
  content: "";
}

.bi-code-square:before {
  content: "";
}

.bi-code:before {
  content: "";
}

.bi-collection-fill:before {
  content: "";
}

.bi-collection-play-fill:before {
  content: "";
}

.bi-collection-play:before {
  content: "";
}

.bi-collection:before {
  content: "";
}

.bi-columns-gap:before {
  content: "";
}

.bi-columns:before {
  content: "";
}

.bi-command:before {
  content: "";
}

.bi-compass-fill:before {
  content: "";
}

.bi-compass:before {
  content: "";
}

.bi-cone-striped:before {
  content: "";
}

.bi-cone:before {
  content: "";
}

.bi-controller:before {
  content: "";
}

.bi-cpu-fill:before {
  content: "";
}

.bi-cpu:before {
  content: "";
}

.bi-credit-card-2-back-fill:before {
  content: "";
}

.bi-credit-card-2-back:before {
  content: "";
}

.bi-credit-card-2-front-fill:before {
  content: "";
}

.bi-credit-card-2-front:before {
  content: "";
}

.bi-credit-card-fill:before {
  content: "";
}

.bi-credit-card:before {
  content: "";
}

.bi-crop:before {
  content: "";
}

.bi-cup-fill:before {
  content: "";
}

.bi-cup-straw:before {
  content: "";
}

.bi-cup:before {
  content: "";
}

.bi-cursor-fill:before {
  content: "";
}

.bi-cursor-text:before {
  content: "";
}

.bi-cursor:before {
  content: "";
}

.bi-dash-circle-dotted:before {
  content: "";
}

.bi-dash-circle-fill:before {
  content: "";
}

.bi-dash-circle:before {
  content: "";
}

.bi-dash-square-dotted:before {
  content: "";
}

.bi-dash-square-fill:before {
  content: "";
}

.bi-dash-square:before {
  content: "";
}

.bi-dash:before {
  content: "";
}

.bi-diagram-2-fill:before {
  content: "";
}

.bi-diagram-2:before {
  content: "";
}

.bi-diagram-3-fill:before {
  content: "";
}

.bi-diagram-3:before {
  content: "";
}

.bi-diamond-fill:before {
  content: "";
}

.bi-diamond-half:before {
  content: "";
}

.bi-diamond:before {
  content: "";
}

.bi-dice-1-fill:before {
  content: "";
}

.bi-dice-1:before {
  content: "";
}

.bi-dice-2-fill:before {
  content: "";
}

.bi-dice-2:before {
  content: "";
}

.bi-dice-3-fill:before {
  content: "";
}

.bi-dice-3:before {
  content: "";
}

.bi-dice-4-fill:before {
  content: "";
}

.bi-dice-4:before {
  content: "";
}

.bi-dice-5-fill:before {
  content: "";
}

.bi-dice-5:before {
  content: "";
}

.bi-dice-6-fill:before {
  content: "";
}

.bi-dice-6:before {
  content: "";
}

.bi-disc-fill:before {
  content: "";
}

.bi-disc:before {
  content: "";
}

.bi-discord:before {
  content: "";
}

.bi-display-fill:before {
  content: "";
}

.bi-display:before {
  content: "";
}

.bi-distribute-horizontal:before {
  content: "";
}

.bi-distribute-vertical:before {
  content: "";
}

.bi-door-closed-fill:before {
  content: "";
}

.bi-door-closed:before {
  content: "";
}

.bi-door-open-fill:before {
  content: "";
}

.bi-door-open:before {
  content: "";
}

.bi-dot:before {
  content: "";
}

.bi-download:before {
  content: "";
}

.bi-droplet-fill:before {
  content: "";
}

.bi-droplet-half:before {
  content: "";
}

.bi-droplet:before {
  content: "";
}

.bi-earbuds:before {
  content: "";
}

.bi-easel-fill:before {
  content: "";
}

.bi-easel:before {
  content: "";
}

.bi-egg-fill:before {
  content: "";
}

.bi-egg-fried:before {
  content: "";
}

.bi-egg:before {
  content: "";
}

.bi-eject-fill:before {
  content: "";
}

.bi-eject:before {
  content: "";
}

.bi-emoji-angry-fill:before {
  content: "";
}

.bi-emoji-angry:before {
  content: "";
}

.bi-emoji-dizzy-fill:before {
  content: "";
}

.bi-emoji-dizzy:before {
  content: "";
}

.bi-emoji-expressionless-fill:before {
  content: "";
}

.bi-emoji-expressionless:before {
  content: "";
}

.bi-emoji-frown-fill:before {
  content: "";
}

.bi-emoji-frown:before {
  content: "";
}

.bi-emoji-heart-eyes-fill:before {
  content: "";
}

.bi-emoji-heart-eyes:before {
  content: "";
}

.bi-emoji-laughing-fill:before {
  content: "";
}

.bi-emoji-laughing:before {
  content: "";
}

.bi-emoji-neutral-fill:before {
  content: "";
}

.bi-emoji-neutral:before {
  content: "";
}

.bi-emoji-smile-fill:before {
  content: "";
}

.bi-emoji-smile-upside-down-fill:before {
  content: "";
}

.bi-emoji-smile-upside-down:before {
  content: "";
}

.bi-emoji-smile:before {
  content: "";
}

.bi-emoji-sunglasses-fill:before {
  content: "";
}

.bi-emoji-sunglasses:before {
  content: "";
}

.bi-emoji-wink-fill:before {
  content: "";
}

.bi-emoji-wink:before {
  content: "";
}

.bi-envelope-fill:before {
  content: "";
}

.bi-envelope-open-fill:before {
  content: "";
}

.bi-envelope-open:before {
  content: "";
}

.bi-envelope:before {
  content: "";
}

.bi-eraser-fill:before {
  content: "";
}

.bi-eraser:before {
  content: "";
}

.bi-exclamation-circle-fill:before {
  content: "";
}

.bi-exclamation-circle:before {
  content: "";
}

.bi-exclamation-diamond-fill:before {
  content: "";
}

.bi-exclamation-diamond:before {
  content: "";
}

.bi-exclamation-octagon-fill:before {
  content: "";
}

.bi-exclamation-octagon:before {
  content: "";
}

.bi-exclamation-square-fill:before {
  content: "";
}

.bi-exclamation-square:before {
  content: "";
}

.bi-exclamation-triangle-fill:before {
  content: "";
}

.bi-exclamation-triangle:before {
  content: "";
}

.bi-exclamation:before {
  content: "";
}

.bi-exclude:before {
  content: "";
}

.bi-eye-fill:before {
  content: "";
}

.bi-eye-slash-fill:before {
  content: "";
}

.bi-eye-slash:before {
  content: "";
}

.bi-eye:before {
  content: "";
}

.bi-eyedropper:before {
  content: "";
}

.bi-eyeglasses:before {
  content: "";
}

.bi-facebook:before {
  content: "";
}

.bi-file-arrow-down-fill:before {
  content: "";
}

.bi-file-arrow-down:before {
  content: "";
}

.bi-file-arrow-up-fill:before {
  content: "";
}

.bi-file-arrow-up:before {
  content: "";
}

.bi-file-bar-graph-fill:before {
  content: "";
}

.bi-file-bar-graph:before {
  content: "";
}

.bi-file-binary-fill:before {
  content: "";
}

.bi-file-binary:before {
  content: "";
}

.bi-file-break-fill:before {
  content: "";
}

.bi-file-break:before {
  content: "";
}

.bi-file-check-fill:before {
  content: "";
}

.bi-file-check:before {
  content: "";
}

.bi-file-code-fill:before {
  content: "";
}

.bi-file-code:before {
  content: "";
}

.bi-file-diff-fill:before {
  content: "";
}

.bi-file-diff:before {
  content: "";
}

.bi-file-earmark-arrow-down-fill:before {
  content: "";
}

.bi-file-earmark-arrow-down:before {
  content: "";
}

.bi-file-earmark-arrow-up-fill:before {
  content: "";
}

.bi-file-earmark-arrow-up:before {
  content: "";
}

.bi-file-earmark-bar-graph-fill:before {
  content: "";
}

.bi-file-earmark-bar-graph:before {
  content: "";
}

.bi-file-earmark-binary-fill:before {
  content: "";
}

.bi-file-earmark-binary:before {
  content: "";
}

.bi-file-earmark-break-fill:before {
  content: "";
}

.bi-file-earmark-break:before {
  content: "";
}

.bi-file-earmark-check-fill:before {
  content: "";
}

.bi-file-earmark-check:before {
  content: "";
}

.bi-file-earmark-code-fill:before {
  content: "";
}

.bi-file-earmark-code:before {
  content: "";
}

.bi-file-earmark-diff-fill:before {
  content: "";
}

.bi-file-earmark-diff:before {
  content: "";
}

.bi-file-earmark-easel-fill:before {
  content: "";
}

.bi-file-earmark-easel:before {
  content: "";
}

.bi-file-earmark-excel-fill:before {
  content: "";
}

.bi-file-earmark-excel:before {
  content: "";
}

.bi-file-earmark-fill:before {
  content: "";
}

.bi-file-earmark-font-fill:before {
  content: "";
}

.bi-file-earmark-font:before {
  content: "";
}

.bi-file-earmark-image-fill:before {
  content: "";
}

.bi-file-earmark-image:before {
  content: "";
}

.bi-file-earmark-lock-fill:before {
  content: "";
}

.bi-file-earmark-lock:before {
  content: "";
}

.bi-file-earmark-lock2-fill:before {
  content: "";
}

.bi-file-earmark-lock2:before {
  content: "";
}

.bi-file-earmark-medical-fill:before {
  content: "";
}

.bi-file-earmark-medical:before {
  content: "";
}

.bi-file-earmark-minus-fill:before {
  content: "";
}

.bi-file-earmark-minus:before {
  content: "";
}

.bi-file-earmark-music-fill:before {
  content: "";
}

.bi-file-earmark-music:before {
  content: "";
}

.bi-file-earmark-person-fill:before {
  content: "";
}

.bi-file-earmark-person:before {
  content: "";
}

.bi-file-earmark-play-fill:before {
  content: "";
}

.bi-file-earmark-play:before {
  content: "";
}

.bi-file-earmark-plus-fill:before {
  content: "";
}

.bi-file-earmark-plus:before {
  content: "";
}

.bi-file-earmark-post-fill:before {
  content: "";
}

.bi-file-earmark-post:before {
  content: "";
}

.bi-file-earmark-ppt-fill:before {
  content: "";
}

.bi-file-earmark-ppt:before {
  content: "";
}

.bi-file-earmark-richtext-fill:before {
  content: "";
}

.bi-file-earmark-richtext:before {
  content: "";
}

.bi-file-earmark-ruled-fill:before {
  content: "";
}

.bi-file-earmark-ruled:before {
  content: "";
}

.bi-file-earmark-slides-fill:before {
  content: "";
}

.bi-file-earmark-slides:before {
  content: "";
}

.bi-file-earmark-spreadsheet-fill:before {
  content: "";
}

.bi-file-earmark-spreadsheet:before {
  content: "";
}

.bi-file-earmark-text-fill:before {
  content: "";
}

.bi-file-earmark-text:before {
  content: "";
}

.bi-file-earmark-word-fill:before {
  content: "";
}

.bi-file-earmark-word:before {
  content: "";
}

.bi-file-earmark-x-fill:before {
  content: "";
}

.bi-file-earmark-x:before {
  content: "";
}

.bi-file-earmark-zip-fill:before {
  content: "";
}

.bi-file-earmark-zip:before {
  content: "";
}

.bi-file-earmark:before {
  content: "";
}

.bi-file-easel-fill:before {
  content: "";
}

.bi-file-easel:before {
  content: "";
}

.bi-file-excel-fill:before {
  content: "";
}

.bi-file-excel:before {
  content: "";
}

.bi-file-fill:before {
  content: "";
}

.bi-file-font-fill:before {
  content: "";
}

.bi-file-font:before {
  content: "";
}

.bi-file-image-fill:before {
  content: "";
}

.bi-file-image:before {
  content: "";
}

.bi-file-lock-fill:before {
  content: "";
}

.bi-file-lock:before {
  content: "";
}

.bi-file-lock2-fill:before {
  content: "";
}

.bi-file-lock2:before {
  content: "";
}

.bi-file-medical-fill:before {
  content: "";
}

.bi-file-medical:before {
  content: "";
}

.bi-file-minus-fill:before {
  content: "";
}

.bi-file-minus:before {
  content: "";
}

.bi-file-music-fill:before {
  content: "";
}

.bi-file-music:before {
  content: "";
}

.bi-file-person-fill:before {
  content: "";
}

.bi-file-person:before {
  content: "";
}

.bi-file-play-fill:before {
  content: "";
}

.bi-file-play:before {
  content: "";
}

.bi-file-plus-fill:before {
  content: "";
}

.bi-file-plus:before {
  content: "";
}

.bi-file-post-fill:before {
  content: "";
}

.bi-file-post:before {
  content: "";
}

.bi-file-ppt-fill:before {
  content: "";
}

.bi-file-ppt:before {
  content: "";
}

.bi-file-richtext-fill:before {
  content: "";
}

.bi-file-richtext:before {
  content: "";
}

.bi-file-ruled-fill:before {
  content: "";
}

.bi-file-ruled:before {
  content: "";
}

.bi-file-slides-fill:before {
  content: "";
}

.bi-file-slides:before {
  content: "";
}

.bi-file-spreadsheet-fill:before {
  content: "";
}

.bi-file-spreadsheet:before {
  content: "";
}

.bi-file-text-fill:before {
  content: "";
}

.bi-file-text:before {
  content: "";
}

.bi-file-word-fill:before {
  content: "";
}

.bi-file-word:before {
  content: "";
}

.bi-file-x-fill:before {
  content: "";
}

.bi-file-x:before {
  content: "";
}

.bi-file-zip-fill:before {
  content: "";
}

.bi-file-zip:before {
  content: "";
}

.bi-file:before {
  content: "";
}

.bi-files-alt:before {
  content: "";
}

.bi-files:before {
  content: "";
}

.bi-film:before {
  content: "";
}

.bi-filter-circle-fill:before {
  content: "";
}

.bi-filter-circle:before {
  content: "";
}

.bi-filter-left:before {
  content: "";
}

.bi-filter-right:before {
  content: "";
}

.bi-filter-square-fill:before {
  content: "";
}

.bi-filter-square:before {
  content: "";
}

.bi-filter:before {
  content: "";
}

.bi-flag-fill:before {
  content: "";
}

.bi-flag:before {
  content: "";
}

.bi-flower1:before {
  content: "";
}

.bi-flower2:before {
  content: "";
}

.bi-flower3:before {
  content: "";
}

.bi-folder-check:before {
  content: "";
}

.bi-folder-fill:before {
  content: "";
}

.bi-folder-minus:before {
  content: "";
}

.bi-folder-plus:before {
  content: "";
}

.bi-folder-symlink-fill:before {
  content: "";
}

.bi-folder-symlink:before {
  content: "";
}

.bi-folder-x:before {
  content: "";
}

.bi-folder:before {
  content: "";
}

.bi-folder2-open:before {
  content: "";
}

.bi-folder2:before {
  content: "";
}

.bi-fonts:before {
  content: "";
}

.bi-forward-fill:before {
  content: "";
}

.bi-forward:before {
  content: "";
}

.bi-front:before {
  content: "";
}

.bi-fullscreen-exit:before {
  content: "";
}

.bi-fullscreen:before {
  content: "";
}

.bi-funnel-fill:before {
  content: "";
}

.bi-funnel:before {
  content: "";
}

.bi-gear-fill:before {
  content: "";
}

.bi-gear-wide-connected:before {
  content: "";
}

.bi-gear-wide:before {
  content: "";
}

.bi-gear:before {
  content: "";
}

.bi-gem:before {
  content: "";
}

.bi-geo-alt-fill:before {
  content: "";
}

.bi-geo-alt:before {
  content: "";
}

.bi-geo-fill:before {
  content: "";
}

.bi-geo:before {
  content: "";
}

.bi-gift-fill:before {
  content: "";
}

.bi-gift:before {
  content: "";
}

.bi-github:before {
  content: "";
}

.bi-globe:before {
  content: "";
}

.bi-globe2:before {
  content: "";
}

.bi-google:before {
  content: "";
}

.bi-graph-down:before {
  content: "";
}

.bi-graph-up:before {
  content: "";
}

.bi-grid-1x2-fill:before {
  content: "";
}

.bi-grid-1x2:before {
  content: "";
}

.bi-grid-3x2-gap-fill:before {
  content: "";
}

.bi-grid-3x2-gap:before {
  content: "";
}

.bi-grid-3x2:before {
  content: "";
}

.bi-grid-3x3-gap-fill:before {
  content: "";
}

.bi-grid-3x3-gap:before {
  content: "";
}

.bi-grid-3x3:before {
  content: "";
}

.bi-grid-fill:before {
  content: "";
}

.bi-grid:before {
  content: "";
}

.bi-grip-horizontal:before {
  content: "";
}

.bi-grip-vertical:before {
  content: "";
}

.bi-hammer:before {
  content: "";
}

.bi-hand-index-fill:before {
  content: "";
}

.bi-hand-index-thumb-fill:before {
  content: "";
}

.bi-hand-index-thumb:before {
  content: "";
}

.bi-hand-index:before {
  content: "";
}

.bi-hand-thumbs-down-fill:before {
  content: "";
}

.bi-hand-thumbs-down:before {
  content: "";
}

.bi-hand-thumbs-up-fill:before {
  content: "";
}

.bi-hand-thumbs-up:before {
  content: "";
}

.bi-handbag-fill:before {
  content: "";
}

.bi-handbag:before {
  content: "";
}

.bi-hash:before {
  content: "";
}

.bi-hdd-fill:before {
  content: "";
}

.bi-hdd-network-fill:before {
  content: "";
}

.bi-hdd-network:before {
  content: "";
}

.bi-hdd-rack-fill:before {
  content: "";
}

.bi-hdd-rack:before {
  content: "";
}

.bi-hdd-stack-fill:before {
  content: "";
}

.bi-hdd-stack:before {
  content: "";
}

.bi-hdd:before {
  content: "";
}

.bi-headphones:before {
  content: "";
}

.bi-headset:before {
  content: "";
}

.bi-heart-fill:before {
  content: "";
}

.bi-heart-half:before {
  content: "";
}

.bi-heart:before {
  content: "";
}

.bi-heptagon-fill:before {
  content: "";
}

.bi-heptagon-half:before {
  content: "";
}

.bi-heptagon:before {
  content: "";
}

.bi-hexagon-fill:before {
  content: "";
}

.bi-hexagon-half:before {
  content: "";
}

.bi-hexagon:before {
  content: "";
}

.bi-hourglass-bottom:before {
  content: "";
}

.bi-hourglass-split:before {
  content: "";
}

.bi-hourglass-top:before {
  content: "";
}

.bi-hourglass:before {
  content: "";
}

.bi-house-door-fill:before {
  content: "";
}

.bi-house-door:before {
  content: "";
}

.bi-house-fill:before {
  content: "";
}

.bi-house:before {
  content: "";
}

.bi-hr:before {
  content: "";
}

.bi-hurricane:before {
  content: "";
}

.bi-image-alt:before {
  content: "";
}

.bi-image-fill:before {
  content: "";
}

.bi-image:before {
  content: "";
}

.bi-images:before {
  content: "";
}

.bi-inbox-fill:before {
  content: "";
}

.bi-inbox:before {
  content: "";
}

.bi-inboxes-fill:before {
  content: "";
}

.bi-inboxes:before {
  content: "";
}

.bi-info-circle-fill:before {
  content: "";
}

.bi-info-circle:before {
  content: "";
}

.bi-info-square-fill:before {
  content: "";
}

.bi-info-square:before {
  content: "";
}

.bi-info:before {
  content: "";
}

.bi-input-cursor-text:before {
  content: "";
}

.bi-input-cursor:before {
  content: "";
}

.bi-instagram:before {
  content: "";
}

.bi-intersect:before {
  content: "";
}

.bi-journal-album:before {
  content: "";
}

.bi-journal-arrow-down:before {
  content: "";
}

.bi-journal-arrow-up:before {
  content: "";
}

.bi-journal-bookmark-fill:before {
  content: "";
}

.bi-journal-bookmark:before {
  content: "";
}

.bi-journal-check:before {
  content: "";
}

.bi-journal-code:before {
  content: "";
}

.bi-journal-medical:before {
  content: "";
}

.bi-journal-minus:before {
  content: "";
}

.bi-journal-plus:before {
  content: "";
}

.bi-journal-richtext:before {
  content: "";
}

.bi-journal-text:before {
  content: "";
}

.bi-journal-x:before {
  content: "";
}

.bi-journal:before {
  content: "";
}

.bi-journals:before {
  content: "";
}

.bi-joystick:before {
  content: "";
}

.bi-justify-left:before {
  content: "";
}

.bi-justify-right:before {
  content: "";
}

.bi-justify:before {
  content: "";
}

.bi-kanban-fill:before {
  content: "";
}

.bi-kanban:before {
  content: "";
}

.bi-key-fill:before {
  content: "";
}

.bi-key:before {
  content: "";
}

.bi-keyboard-fill:before {
  content: "";
}

.bi-keyboard:before {
  content: "";
}

.bi-ladder:before {
  content: "";
}

.bi-lamp-fill:before {
  content: "";
}

.bi-lamp:before {
  content: "";
}

.bi-laptop-fill:before {
  content: "";
}

.bi-laptop:before {
  content: "";
}

.bi-layer-backward:before {
  content: "";
}

.bi-layer-forward:before {
  content: "";
}

.bi-layers-fill:before {
  content: "";
}

.bi-layers-half:before {
  content: "";
}

.bi-layers:before {
  content: "";
}

.bi-layout-sidebar-inset-reverse:before {
  content: "";
}

.bi-layout-sidebar-inset:before {
  content: "";
}

.bi-layout-sidebar-reverse:before {
  content: "";
}

.bi-layout-sidebar:before {
  content: "";
}

.bi-layout-split:before {
  content: "";
}

.bi-layout-text-sidebar-reverse:before {
  content: "";
}

.bi-layout-text-sidebar:before {
  content: "";
}

.bi-layout-text-window-reverse:before {
  content: "";
}

.bi-layout-text-window:before {
  content: "";
}

.bi-layout-three-columns:before {
  content: "";
}

.bi-layout-wtf:before {
  content: "";
}

.bi-life-preserver:before {
  content: "";
}

.bi-lightbulb-fill:before {
  content: "";
}

.bi-lightbulb-off-fill:before {
  content: "";
}

.bi-lightbulb-off:before {
  content: "";
}

.bi-lightbulb:before {
  content: "";
}

.bi-lightning-charge-fill:before {
  content: "";
}

.bi-lightning-charge:before {
  content: "";
}

.bi-lightning-fill:before {
  content: "";
}

.bi-lightning:before {
  content: "";
}

.bi-link-45deg:before {
  content: "";
}

.bi-link:before {
  content: "";
}

.bi-linkedin:before {
  content: "";
}

.bi-list-check:before {
  content: "";
}

.bi-list-nested:before {
  content: "";
}

.bi-list-ol:before {
  content: "";
}

.bi-list-stars:before {
  content: "";
}

.bi-list-task:before {
  content: "";
}

.bi-list-ul:before {
  content: "";
}

.bi-list:before {
  content: "";
}

.bi-lock-fill:before {
  content: "";
}

.bi-lock:before {
  content: "";
}

.bi-mailbox:before {
  content: "";
}

.bi-mailbox2:before {
  content: "";
}

.bi-map-fill:before {
  content: "";
}

.bi-map:before {
  content: "";
}

.bi-markdown-fill:before {
  content: "";
}

.bi-markdown:before {
  content: "";
}

.bi-mask:before {
  content: "";
}

.bi-megaphone-fill:before {
  content: "";
}

.bi-megaphone:before {
  content: "";
}

.bi-menu-app-fill:before {
  content: "";
}

.bi-menu-app:before {
  content: "";
}

.bi-menu-button-fill:before {
  content: "";
}

.bi-menu-button-wide-fill:before {
  content: "";
}

.bi-menu-button-wide:before {
  content: "";
}

.bi-menu-button:before {
  content: "";
}

.bi-menu-down:before {
  content: "";
}

.bi-menu-up:before {
  content: "";
}

.bi-mic-fill:before {
  content: "";
}

.bi-mic-mute-fill:before {
  content: "";
}

.bi-mic-mute:before {
  content: "";
}

.bi-mic:before {
  content: "";
}

.bi-minecart-loaded:before {
  content: "";
}

.bi-minecart:before {
  content: "";
}

.bi-moisture:before {
  content: "";
}

.bi-moon-fill:before {
  content: "";
}

.bi-moon-stars-fill:before {
  content: "";
}

.bi-moon-stars:before {
  content: "";
}

.bi-moon:before {
  content: "";
}

.bi-mouse-fill:before {
  content: "";
}

.bi-mouse:before {
  content: "";
}

.bi-mouse2-fill:before {
  content: "";
}

.bi-mouse2:before {
  content: "";
}

.bi-mouse3-fill:before {
  content: "";
}

.bi-mouse3:before {
  content: "";
}

.bi-music-note-beamed:before {
  content: "";
}

.bi-music-note-list:before {
  content: "";
}

.bi-music-note:before {
  content: "";
}

.bi-music-player-fill:before {
  content: "";
}

.bi-music-player:before {
  content: "";
}

.bi-newspaper:before {
  content: "";
}

.bi-node-minus-fill:before {
  content: "";
}

.bi-node-minus:before {
  content: "";
}

.bi-node-plus-fill:before {
  content: "";
}

.bi-node-plus:before {
  content: "";
}

.bi-nut-fill:before {
  content: "";
}

.bi-nut:before {
  content: "";
}

.bi-octagon-fill:before {
  content: "";
}

.bi-octagon-half:before {
  content: "";
}

.bi-octagon:before {
  content: "";
}

.bi-option:before {
  content: "";
}

.bi-outlet:before {
  content: "";
}

.bi-paint-bucket:before {
  content: "";
}

.bi-palette-fill:before {
  content: "";
}

.bi-palette:before {
  content: "";
}

.bi-palette2:before {
  content: "";
}

.bi-paperclip:before {
  content: "";
}

.bi-paragraph:before {
  content: "";
}

.bi-patch-check-fill:before {
  content: "";
}

.bi-patch-check:before {
  content: "";
}

.bi-patch-exclamation-fill:before {
  content: "";
}

.bi-patch-exclamation:before {
  content: "";
}

.bi-patch-minus-fill:before {
  content: "";
}

.bi-patch-minus:before {
  content: "";
}

.bi-patch-plus-fill:before {
  content: "";
}

.bi-patch-plus:before {
  content: "";
}

.bi-patch-question-fill:before {
  content: "";
}

.bi-patch-question:before {
  content: "";
}

.bi-pause-btn-fill:before {
  content: "";
}

.bi-pause-btn:before {
  content: "";
}

.bi-pause-circle-fill:before {
  content: "";
}

.bi-pause-circle:before {
  content: "";
}

.bi-pause-fill:before {
  content: "";
}

.bi-pause:before {
  content: "";
}

.bi-peace-fill:before {
  content: "";
}

.bi-peace:before {
  content: "";
}

.bi-pen-fill:before {
  content: "";
}

.bi-pen:before {
  content: "";
}

.bi-pencil-fill:before {
  content: "";
}

.bi-pencil-square:before {
  content: "";
}

.bi-pencil:before {
  content: "";
}

.bi-pentagon-fill:before {
  content: "";
}

.bi-pentagon-half:before {
  content: "";
}

.bi-pentagon:before {
  content: "";
}

.bi-people-fill:before {
  content: "";
}

.bi-people:before {
  content: "";
}

.bi-percent:before {
  content: "";
}

.bi-person-badge-fill:before {
  content: "";
}

.bi-person-badge:before {
  content: "";
}

.bi-person-bounding-box:before {
  content: "";
}

.bi-person-check-fill:before {
  content: "";
}

.bi-person-check:before {
  content: "";
}

.bi-person-circle:before {
  content: "";
}

.bi-person-dash-fill:before {
  content: "";
}

.bi-person-dash:before {
  content: "";
}

.bi-person-fill:before {
  content: "";
}

.bi-person-lines-fill:before {
  content: "";
}

.bi-person-plus-fill:before {
  content: "";
}

.bi-person-plus:before {
  content: "";
}

.bi-person-square:before {
  content: "";
}

.bi-person-x-fill:before {
  content: "";
}

.bi-person-x:before {
  content: "";
}

.bi-person:before {
  content: "";
}

.bi-phone-fill:before {
  content: "";
}

.bi-phone-landscape-fill:before {
  content: "";
}

.bi-phone-landscape:before {
  content: "";
}

.bi-phone-vibrate-fill:before {
  content: "";
}

.bi-phone-vibrate:before {
  content: "";
}

.bi-phone:before {
  content: "";
}

.bi-pie-chart-fill:before {
  content: "";
}

.bi-pie-chart:before {
  content: "";
}

.bi-pin-angle-fill:before {
  content: "";
}

.bi-pin-angle:before {
  content: "";
}

.bi-pin-fill:before {
  content: "";
}

.bi-pin:before {
  content: "";
}

.bi-pip-fill:before {
  content: "";
}

.bi-pip:before {
  content: "";
}

.bi-play-btn-fill:before {
  content: "";
}

.bi-play-btn:before {
  content: "";
}

.bi-play-circle-fill:before {
  content: "";
}

.bi-play-circle:before {
  content: "";
}

.bi-play-fill:before {
  content: "";
}

.bi-play:before {
  content: "";
}

.bi-plug-fill:before {
  content: "";
}

.bi-plug:before {
  content: "";
}

.bi-plus-circle-dotted:before {
  content: "";
}

.bi-plus-circle-fill:before {
  content: "";
}

.bi-plus-circle:before {
  content: "";
}

.bi-plus-square-dotted:before {
  content: "";
}

.bi-plus-square-fill:before {
  content: "";
}

.bi-plus-square:before {
  content: "";
}

.bi-plus:before {
  content: "";
}

.bi-power:before {
  content: "";
}

.bi-printer-fill:before {
  content: "";
}

.bi-printer:before {
  content: "";
}

.bi-puzzle-fill:before {
  content: "";
}

.bi-puzzle:before {
  content: "";
}

.bi-question-circle-fill:before {
  content: "";
}

.bi-question-circle:before {
  content: "";
}

.bi-question-diamond-fill:before {
  content: "";
}

.bi-question-diamond:before {
  content: "";
}

.bi-question-octagon-fill:before {
  content: "";
}

.bi-question-octagon:before {
  content: "";
}

.bi-question-square-fill:before {
  content: "";
}

.bi-question-square:before {
  content: "";
}

.bi-question:before {
  content: "";
}

.bi-rainbow:before {
  content: "";
}

.bi-receipt-cutoff:before {
  content: "";
}

.bi-receipt:before {
  content: "";
}

.bi-reception-0:before {
  content: "";
}

.bi-reception-1:before {
  content: "";
}

.bi-reception-2:before {
  content: "";
}

.bi-reception-3:before {
  content: "";
}

.bi-reception-4:before {
  content: "";
}

.bi-record-btn-fill:before {
  content: "";
}

.bi-record-btn:before {
  content: "";
}

.bi-record-circle-fill:before {
  content: "";
}

.bi-record-circle:before {
  content: "";
}

.bi-record-fill:before {
  content: "";
}

.bi-record:before {
  content: "";
}

.bi-record2-fill:before {
  content: "";
}

.bi-record2:before {
  content: "";
}

.bi-reply-all-fill:before {
  content: "";
}

.bi-reply-all:before {
  content: "";
}

.bi-reply-fill:before {
  content: "";
}

.bi-reply:before {
  content: "";
}

.bi-rss-fill:before {
  content: "";
}

.bi-rss:before {
  content: "";
}

.bi-rulers:before {
  content: "";
}

.bi-save-fill:before {
  content: "";
}

.bi-save:before {
  content: "";
}

.bi-save2-fill:before {
  content: "";
}

.bi-save2:before {
  content: "";
}

.bi-scissors:before {
  content: "";
}

.bi-screwdriver:before {
  content: "";
}

.bi-search:before {
  content: "";
}

.bi-segmented-nav:before {
  content: "";
}

.bi-server:before {
  content: "";
}

.bi-share-fill:before {
  content: "";
}

.bi-share:before {
  content: "";
}

.bi-shield-check:before {
  content: "";
}

.bi-shield-exclamation:before {
  content: "";
}

.bi-shield-fill-check:before {
  content: "";
}

.bi-shield-fill-exclamation:before {
  content: "";
}

.bi-shield-fill-minus:before {
  content: "";
}

.bi-shield-fill-plus:before {
  content: "";
}

.bi-shield-fill-x:before {
  content: "";
}

.bi-shield-fill:before {
  content: "";
}

.bi-shield-lock-fill:before {
  content: "";
}

.bi-shield-lock:before {
  content: "";
}

.bi-shield-minus:before {
  content: "";
}

.bi-shield-plus:before {
  content: "";
}

.bi-shield-shaded:before {
  content: "";
}

.bi-shield-slash-fill:before {
  content: "";
}

.bi-shield-slash:before {
  content: "";
}

.bi-shield-x:before {
  content: "";
}

.bi-shield:before {
  content: "";
}

.bi-shift-fill:before {
  content: "";
}

.bi-shift:before {
  content: "";
}

.bi-shop-window:before {
  content: "";
}

.bi-shop:before {
  content: "";
}

.bi-shuffle:before {
  content: "";
}

.bi-signpost-2-fill:before {
  content: "";
}

.bi-signpost-2:before {
  content: "";
}

.bi-signpost-fill:before {
  content: "";
}

.bi-signpost-split-fill:before {
  content: "";
}

.bi-signpost-split:before {
  content: "";
}

.bi-signpost:before {
  content: "";
}

.bi-sim-fill:before {
  content: "";
}

.bi-sim:before {
  content: "";
}

.bi-skip-backward-btn-fill:before {
  content: "";
}

.bi-skip-backward-btn:before {
  content: "";
}

.bi-skip-backward-circle-fill:before {
  content: "";
}

.bi-skip-backward-circle:before {
  content: "";
}

.bi-skip-backward-fill:before {
  content: "";
}

.bi-skip-backward:before {
  content: "";
}

.bi-skip-end-btn-fill:before {
  content: "";
}

.bi-skip-end-btn:before {
  content: "";
}

.bi-skip-end-circle-fill:before {
  content: "";
}

.bi-skip-end-circle:before {
  content: "";
}

.bi-skip-end-fill:before {
  content: "";
}

.bi-skip-end:before {
  content: "";
}

.bi-skip-forward-btn-fill:before {
  content: "";
}

.bi-skip-forward-btn:before {
  content: "";
}

.bi-skip-forward-circle-fill:before {
  content: "";
}

.bi-skip-forward-circle:before {
  content: "";
}

.bi-skip-forward-fill:before {
  content: "";
}

.bi-skip-forward:before {
  content: "";
}

.bi-skip-start-btn-fill:before {
  content: "";
}

.bi-skip-start-btn:before {
  content: "";
}

.bi-skip-start-circle-fill:before {
  content: "";
}

.bi-skip-start-circle:before {
  content: "";
}

.bi-skip-start-fill:before {
  content: "";
}

.bi-skip-start:before {
  content: "";
}

.bi-slack:before {
  content: "";
}

.bi-slash-circle-fill:before {
  content: "";
}

.bi-slash-circle:before {
  content: "";
}

.bi-slash-square-fill:before {
  content: "";
}

.bi-slash-square:before {
  content: "";
}

.bi-slash:before {
  content: "";
}

.bi-sliders:before {
  content: "";
}

.bi-smartwatch:before {
  content: "";
}

.bi-snow:before {
  content: "";
}

.bi-snow2:before {
  content: "";
}

.bi-snow3:before {
  content: "";
}

.bi-sort-alpha-down-alt:before {
  content: "";
}

.bi-sort-alpha-down:before {
  content: "";
}

.bi-sort-alpha-up-alt:before {
  content: "";
}

.bi-sort-alpha-up:before {
  content: "";
}

.bi-sort-down-alt:before {
  content: "";
}

.bi-sort-down:before {
  content: "";
}

.bi-sort-numeric-down-alt:before {
  content: "";
}

.bi-sort-numeric-down:before {
  content: "";
}

.bi-sort-numeric-up-alt:before {
  content: "";
}

.bi-sort-numeric-up:before {
  content: "";
}

.bi-sort-up-alt:before {
  content: "";
}

.bi-sort-up:before {
  content: "";
}

.bi-soundwave:before {
  content: "";
}

.bi-speaker-fill:before {
  content: "";
}

.bi-speaker:before {
  content: "";
}

.bi-speedometer:before {
  content: "";
}

.bi-speedometer2:before {
  content: "";
}

.bi-spellcheck:before {
  content: "";
}

.bi-square-fill:before {
  content: "";
}

.bi-square-half:before {
  content: "";
}

.bi-square:before {
  content: "";
}

.bi-stack:before {
  content: "";
}

.bi-star-fill:before {
  content: "";
}

.bi-star-half:before {
  content: "";
}

.bi-star:before {
  content: "";
}

.bi-stars:before {
  content: "";
}

.bi-stickies-fill:before {
  content: "";
}

.bi-stickies:before {
  content: "";
}

.bi-sticky-fill:before {
  content: "";
}

.bi-sticky:before {
  content: "";
}

.bi-stop-btn-fill:before {
  content: "";
}

.bi-stop-btn:before {
  content: "";
}

.bi-stop-circle-fill:before {
  content: "";
}

.bi-stop-circle:before {
  content: "";
}

.bi-stop-fill:before {
  content: "";
}

.bi-stop:before {
  content: "";
}

.bi-stoplights-fill:before {
  content: "";
}

.bi-stoplights:before {
  content: "";
}

.bi-stopwatch-fill:before {
  content: "";
}

.bi-stopwatch:before {
  content: "";
}

.bi-subtract:before {
  content: "";
}

.bi-suit-club-fill:before {
  content: "";
}

.bi-suit-club:before {
  content: "";
}

.bi-suit-diamond-fill:before {
  content: "";
}

.bi-suit-diamond:before {
  content: "";
}

.bi-suit-heart-fill:before {
  content: "";
}

.bi-suit-heart:before {
  content: "";
}

.bi-suit-spade-fill:before {
  content: "";
}

.bi-suit-spade:before {
  content: "";
}

.bi-sun-fill:before {
  content: "";
}

.bi-sun:before {
  content: "";
}

.bi-sunglasses:before {
  content: "";
}

.bi-sunrise-fill:before {
  content: "";
}

.bi-sunrise:before {
  content: "";
}

.bi-sunset-fill:before {
  content: "";
}

.bi-sunset:before {
  content: "";
}

.bi-symmetry-horizontal:before {
  content: "";
}

.bi-symmetry-vertical:before {
  content: "";
}

.bi-table:before {
  content: "";
}

.bi-tablet-fill:before {
  content: "";
}

.bi-tablet-landscape-fill:before {
  content: "";
}

.bi-tablet-landscape:before {
  content: "";
}

.bi-tablet:before {
  content: "";
}

.bi-tag-fill:before {
  content: "";
}

.bi-tag:before {
  content: "";
}

.bi-tags-fill:before {
  content: "";
}

.bi-tags:before {
  content: "";
}

.bi-telegram:before {
  content: "";
}

.bi-telephone-fill:before {
  content: "";
}

.bi-telephone-forward-fill:before {
  content: "";
}

.bi-telephone-forward:before {
  content: "";
}

.bi-telephone-inbound-fill:before {
  content: "";
}

.bi-telephone-inbound:before {
  content: "";
}

.bi-telephone-minus-fill:before {
  content: "";
}

.bi-telephone-minus:before {
  content: "";
}

.bi-telephone-outbound-fill:before {
  content: "";
}

.bi-telephone-outbound:before {
  content: "";
}

.bi-telephone-plus-fill:before {
  content: "";
}

.bi-telephone-plus:before {
  content: "";
}

.bi-telephone-x-fill:before {
  content: "";
}

.bi-telephone-x:before {
  content: "";
}

.bi-telephone:before {
  content: "";
}

.bi-terminal-fill:before {
  content: "";
}

.bi-terminal:before {
  content: "";
}

.bi-text-center:before {
  content: "";
}

.bi-text-indent-left:before {
  content: "";
}

.bi-text-indent-right:before {
  content: "";
}

.bi-text-left:before {
  content: "";
}

.bi-text-paragraph:before {
  content: "";
}

.bi-text-right:before {
  content: "";
}

.bi-textarea-resize:before {
  content: "";
}

.bi-textarea-t:before {
  content: "";
}

.bi-textarea:before {
  content: "";
}

.bi-thermometer-half:before {
  content: "";
}

.bi-thermometer-high:before {
  content: "";
}

.bi-thermometer-low:before {
  content: "";
}

.bi-thermometer-snow:before {
  content: "";
}

.bi-thermometer-sun:before {
  content: "";
}

.bi-thermometer:before {
  content: "";
}

.bi-three-dots-vertical:before {
  content: "";
}

.bi-three-dots:before {
  content: "";
}

.bi-toggle-off:before {
  content: "";
}

.bi-toggle-on:before {
  content: "";
}

.bi-toggle2-off:before {
  content: "";
}

.bi-toggle2-on:before {
  content: "";
}

.bi-toggles:before {
  content: "";
}

.bi-toggles2:before {
  content: "";
}

.bi-tools:before {
  content: "";
}

.bi-tornado:before {
  content: "";
}

.bi-trash-fill:before {
  content: "";
}

.bi-trash:before {
  content: "";
}

.bi-trash2-fill:before {
  content: "";
}

.bi-trash2:before {
  content: "";
}

.bi-tree-fill:before {
  content: "";
}

.bi-tree:before {
  content: "";
}

.bi-triangle-fill:before {
  content: "";
}

.bi-triangle-half:before {
  content: "";
}

.bi-triangle:before {
  content: "";
}

.bi-trophy-fill:before {
  content: "";
}

.bi-trophy:before {
  content: "";
}

.bi-tropical-storm:before {
  content: "";
}

.bi-truck-flatbed:before {
  content: "";
}

.bi-truck:before {
  content: "";
}

.bi-tsunami:before {
  content: "";
}

.bi-tv-fill:before {
  content: "";
}

.bi-tv:before {
  content: "";
}

.bi-twitch:before {
  content: "";
}

.bi-twitter:before {
  content: "";
}

.bi-type-bold:before {
  content: "";
}

.bi-type-h1:before {
  content: "";
}

.bi-type-h2:before {
  content: "";
}

.bi-type-h3:before {
  content: "";
}

.bi-type-italic:before {
  content: "";
}

.bi-type-strikethrough:before {
  content: "";
}

.bi-type-underline:before {
  content: "";
}

.bi-type:before {
  content: "";
}

.bi-ui-checks-grid:before {
  content: "";
}

.bi-ui-checks:before {
  content: "";
}

.bi-ui-radios-grid:before {
  content: "";
}

.bi-ui-radios:before {
  content: "";
}

.bi-umbrella-fill:before {
  content: "";
}

.bi-umbrella:before {
  content: "";
}

.bi-union:before {
  content: "";
}

.bi-unlock-fill:before {
  content: "";
}

.bi-unlock:before {
  content: "";
}

.bi-upc-scan:before {
  content: "";
}

.bi-upc:before {
  content: "";
}

.bi-upload:before {
  content: "";
}

.bi-vector-pen:before {
  content: "";
}

.bi-view-list:before {
  content: "";
}

.bi-view-stacked:before {
  content: "";
}

.bi-vinyl-fill:before {
  content: "";
}

.bi-vinyl:before {
  content: "";
}

.bi-voicemail:before {
  content: "";
}

.bi-volume-down-fill:before {
  content: "";
}

.bi-volume-down:before {
  content: "";
}

.bi-volume-mute-fill:before {
  content: "";
}

.bi-volume-mute:before {
  content: "";
}

.bi-volume-off-fill:before {
  content: "";
}

.bi-volume-off:before {
  content: "";
}

.bi-volume-up-fill:before {
  content: "";
}

.bi-volume-up:before {
  content: "";
}

.bi-vr:before {
  content: "";
}

.bi-wallet-fill:before {
  content: "";
}

.bi-wallet:before {
  content: "";
}

.bi-wallet2:before {
  content: "";
}

.bi-watch:before {
  content: "";
}

.bi-water:before {
  content: "";
}

.bi-whatsapp:before {
  content: "";
}

.bi-wifi-1:before {
  content: "";
}

.bi-wifi-2:before {
  content: "";
}

.bi-wifi-off:before {
  content: "";
}

.bi-wifi:before {
  content: "";
}

.bi-wind:before {
  content: "";
}

.bi-window-dock:before {
  content: "";
}

.bi-window-sidebar:before {
  content: "";
}

.bi-window:before {
  content: "";
}

.bi-wrench:before {
  content: "";
}

.bi-x-circle-fill:before {
  content: "";
}

.bi-x-circle:before {
  content: "";
}

.bi-x-diamond-fill:before {
  content: "";
}

.bi-x-diamond:before {
  content: "";
}

.bi-x-octagon-fill:before {
  content: "";
}

.bi-x-octagon:before {
  content: "";
}

.bi-x-square-fill:before {
  content: "";
}

.bi-x-square:before {
  content: "";
}

.bi-x:before {
  content: "";
}

.bi-youtube:before {
  content: "";
}

.bi-zoom-in:before {
  content: "";
}

.bi-zoom-out:before {
  content: "";
}

.bi-bank:before {
  content: "";
}

.bi-bank2:before {
  content: "";
}

.bi-bell-slash-fill:before {
  content: "";
}

.bi-bell-slash:before {
  content: "";
}

.bi-cash-coin:before {
  content: "";
}

.bi-check-lg:before {
  content: "";
}

.bi-coin:before {
  content: "";
}

.bi-currency-bitcoin:before {
  content: "";
}

.bi-currency-dollar:before {
  content: "";
}

.bi-currency-euro:before {
  content: "";
}

.bi-currency-exchange:before {
  content: "";
}

.bi-currency-pound:before {
  content: "";
}

.bi-currency-yen:before {
  content: "";
}

.bi-dash-lg:before {
  content: "";
}

.bi-exclamation-lg:before {
  content: "";
}

.bi-file-earmark-pdf-fill:before {
  content: "";
}

.bi-file-earmark-pdf:before {
  content: "";
}

.bi-file-pdf-fill:before {
  content: "";
}

.bi-file-pdf:before {
  content: "";
}

.bi-gender-ambiguous:before {
  content: "";
}

.bi-gender-female:before {
  content: "";
}

.bi-gender-male:before {
  content: "";
}

.bi-gender-trans:before {
  content: "";
}

.bi-headset-vr:before {
  content: "";
}

.bi-info-lg:before {
  content: "";
}

.bi-mastodon:before {
  content: "";
}

.bi-messenger:before {
  content: "";
}

.bi-piggy-bank-fill:before {
  content: "";
}

.bi-piggy-bank:before {
  content: "";
}

.bi-pin-map-fill:before {
  content: "";
}

.bi-pin-map:before {
  content: "";
}

.bi-plus-lg:before {
  content: "";
}

.bi-question-lg:before {
  content: "";
}

.bi-recycle:before {
  content: "";
}

.bi-reddit:before {
  content: "";
}

.bi-safe-fill:before {
  content: "";
}

.bi-safe2-fill:before {
  content: "";
}

.bi-safe2:before {
  content: "";
}

.bi-sd-card-fill:before {
  content: "";
}

.bi-sd-card:before {
  content: "";
}

.bi-skype:before {
  content: "";
}

.bi-slash-lg:before {
  content: "";
}

.bi-translate:before {
  content: "";
}

.bi-x-lg:before {
  content: "";
}

.bi-safe:before {
  content: "";
}

.bi-apple:before {
  content: "";
}

.bi-microsoft:before {
  content: "";
}

.bi-windows:before {
  content: "";
}

.bi-behance:before {
  content: "";
}

.bi-dribbble:before {
  content: "";
}

.bi-line:before {
  content: "";
}

.bi-medium:before {
  content: "";
}

.bi-paypal:before {
  content: "";
}

.bi-pinterest:before {
  content: "";
}

.bi-signal:before {
  content: "";
}

.bi-snapchat:before {
  content: "";
}

.bi-spotify:before {
  content: "";
}

.bi-stack-overflow:before {
  content: "";
}

.bi-strava:before {
  content: "";
}

.bi-wordpress:before {
  content: "";
}

.bi-vimeo:before {
  content: "";
}

.bi-activity:before {
  content: "";
}

.bi-easel2-fill:before {
  content: "";
}

.bi-easel2:before {
  content: "";
}

.bi-easel3-fill:before {
  content: "";
}

.bi-easel3:before {
  content: "";
}

.bi-fan:before {
  content: "";
}

.bi-fingerprint:before {
  content: "";
}

.bi-graph-down-arrow:before {
  content: "";
}

.bi-graph-up-arrow:before {
  content: "";
}

.bi-hypnotize:before {
  content: "";
}

.bi-magic:before {
  content: "";
}

.bi-person-rolodex:before {
  content: "";
}

.bi-person-video:before {
  content: "";
}

.bi-person-video2:before {
  content: "";
}

.bi-person-video3:before {
  content: "";
}

.bi-person-workspace:before {
  content: "";
}

.bi-radioactive:before {
  content: "";
}

.bi-webcam-fill:before {
  content: "";
}

.bi-webcam:before {
  content: "";
}

.bi-yin-yang:before {
  content: "";
}

.bi-bandaid-fill:before {
  content: "";
}

.bi-bandaid:before {
  content: "";
}

.bi-bluetooth:before {
  content: "";
}

.bi-body-text:before {
  content: "";
}

.bi-boombox:before {
  content: "";
}

.bi-boxes:before {
  content: "";
}

.bi-dpad-fill:before {
  content: "";
}

.bi-dpad:before {
  content: "";
}

.bi-ear-fill:before {
  content: "";
}

.bi-ear:before {
  content: "";
}

.bi-envelope-check-1:before {
  content: "";
}

.bi-envelope-check-fill:before {
  content: "";
}

.bi-envelope-check:before {
  content: "";
}

.bi-envelope-dash-1:before {
  content: "";
}

.bi-envelope-dash-fill:before {
  content: "";
}

.bi-envelope-dash:before {
  content: "";
}

.bi-envelope-exclamation-1:before {
  content: "";
}

.bi-envelope-exclamation-fill:before {
  content: "";
}

.bi-envelope-exclamation:before {
  content: "";
}

.bi-envelope-plus-fill:before {
  content: "";
}

.bi-envelope-plus:before {
  content: "";
}

.bi-envelope-slash-1:before {
  content: "";
}

.bi-envelope-slash-fill:before {
  content: "";
}

.bi-envelope-slash:before {
  content: "";
}

.bi-envelope-x-1:before {
  content: "";
}

.bi-envelope-x-fill:before {
  content: "";
}

.bi-envelope-x:before {
  content: "";
}

.bi-explicit-fill:before {
  content: "";
}

.bi-explicit:before {
  content: "";
}

.bi-git:before {
  content: "";
}

.bi-infinity:before {
  content: "";
}

.bi-list-columns-reverse:before {
  content: "";
}

.bi-list-columns:before {
  content: "";
}

.bi-meta:before {
  content: "";
}

.bi-mortorboard-fill:before {
  content: "";
}

.bi-mortorboard:before {
  content: "";
}

.bi-nintendo-switch:before {
  content: "";
}

.bi-pc-display-horizontal:before {
  content: "";
}

.bi-pc-display:before {
  content: "";
}

.bi-pc-horizontal:before {
  content: "";
}

.bi-pc:before {
  content: "";
}

.bi-playstation:before {
  content: "";
}

.bi-plus-slash-minus:before {
  content: "";
}

.bi-projector-fill:before {
  content: "";
}

.bi-projector:before {
  content: "";
}

.bi-qr-code-scan:before {
  content: "";
}

.bi-qr-code:before {
  content: "";
}

.bi-quora:before {
  content: "";
}

.bi-quote:before {
  content: "";
}

.bi-robot:before {
  content: "";
}

.bi-send-check-fill:before {
  content: "";
}

.bi-send-check:before {
  content: "";
}

.bi-send-dash-fill:before {
  content: "";
}

.bi-send-dash:before {
  content: "";
}

.bi-send-exclamation-1:before {
  content: "";
}

.bi-send-exclamation-fill:before {
  content: "";
}

.bi-send-exclamation:before {
  content: "";
}

.bi-send-fill:before {
  content: "";
}

.bi-send-plus-fill:before {
  content: "";
}

.bi-send-plus:before {
  content: "";
}

.bi-send-slash-fill:before {
  content: "";
}

.bi-send-slash:before {
  content: "";
}

.bi-send-x-fill:before {
  content: "";
}

.bi-send-x:before {
  content: "";
}

.bi-send:before {
  content: "";
}

.bi-steam:before {
  content: "";
}

.bi-terminal-dash-1:before {
  content: "";
}

.bi-terminal-dash:before {
  content: "";
}

.bi-terminal-plus:before {
  content: "";
}

.bi-terminal-split:before {
  content: "";
}

.bi-ticket-detailed-fill:before {
  content: "";
}

.bi-ticket-detailed:before {
  content: "";
}

.bi-ticket-fill:before {
  content: "";
}

.bi-ticket-perforated-fill:before {
  content: "";
}

.bi-ticket-perforated:before {
  content: "";
}

.bi-ticket:before {
  content: "";
}

.bi-tiktok:before {
  content: "";
}

.bi-window-dash:before {
  content: "";
}

.bi-window-desktop:before {
  content: "";
}

.bi-window-fullscreen:before {
  content: "";
}

.bi-window-plus:before {
  content: "";
}

.bi-window-split:before {
  content: "";
}

.bi-window-stack:before {
  content: "";
}

.bi-window-x:before {
  content: "";
}

.bi-xbox:before {
  content: "";
}

.bi-ethernet:before {
  content: "";
}

.bi-hdmi-fill:before {
  content: "";
}

.bi-hdmi:before {
  content: "";
}

.bi-usb-c-fill:before {
  content: "";
}

.bi-usb-c:before {
  content: "";
}

.bi-usb-fill:before {
  content: "";
}

.bi-usb-plug-fill:before {
  content: "";
}

.bi-usb-plug:before {
  content: "";
}

.bi-usb-symbol:before {
  content: "";
}

.bi-usb:before {
  content: "";
}

.bi-boombox-fill:before {
  content: "";
}

.bi-displayport-1:before {
  content: "";
}

.bi-displayport:before {
  content: "";
}

.bi-gpu-card:before {
  content: "";
}

.bi-memory:before {
  content: "";
}

.bi-modem-fill:before {
  content: "";
}

.bi-modem:before {
  content: "";
}

.bi-motherboard-fill:before {
  content: "";
}

.bi-motherboard:before {
  content: "";
}

.bi-optical-audio-fill:before {
  content: "";
}

.bi-optical-audio:before {
  content: "";
}

.bi-pci-card:before {
  content: "";
}

.bi-router-fill:before {
  content: "";
}

.bi-router:before {
  content: "";
}

.bi-ssd-fill:before {
  content: "";
}

.bi-ssd:before {
  content: "";
}

.bi-thunderbolt-fill:before {
  content: "";
}

.bi-thunderbolt:before {
  content: "";
}

.bi-usb-drive-fill:before {
  content: "";
}

.bi-usb-drive:before {
  content: "";
}

.bi-usb-micro-fill:before {
  content: "";
}

.bi-usb-micro:before {
  content: "";
}

.bi-usb-mini-fill:before {
  content: "";
}

.bi-usb-mini:before {
  content: "";
}

.bi-cloud-haze2:before {
  content: "";
}

.bi-device-hdd-fill:before {
  content: "";
}

.bi-device-hdd:before {
  content: "";
}

.bi-device-ssd-fill:before {
  content: "";
}

.bi-device-ssd:before {
  content: "";
}

.bi-displayport-fill:before {
  content: "";
}

.bi-mortarboard-fill:before {
  content: "";
}

.bi-mortarboard:before {
  content: "";
}

.bi-terminal-x:before {
  content: "";
}

.bi-arrow-through-heart-fill:before {
  content: "";
}

.bi-arrow-through-heart:before {
  content: "";
}

.bi-badge-sd-fill:before {
  content: "";
}

.bi-badge-sd:before {
  content: "";
}

.bi-bag-heart-fill:before {
  content: "";
}

.bi-bag-heart:before {
  content: "";
}

.bi-balloon-fill:before {
  content: "";
}

.bi-balloon-heart-fill:before {
  content: "";
}

.bi-balloon-heart:before {
  content: "";
}

.bi-balloon:before {
  content: "";
}

.bi-box2-fill:before {
  content: "";
}

.bi-box2-heart-fill:before {
  content: "";
}

.bi-box2-heart:before {
  content: "";
}

.bi-box2:before {
  content: "";
}

.bi-braces-asterisk:before {
  content: "";
}

.bi-calendar-heart-fill:before {
  content: "";
}

.bi-calendar-heart:before {
  content: "";
}

.bi-calendar2-heart-fill:before {
  content: "";
}

.bi-calendar2-heart:before {
  content: "";
}

.bi-chat-heart-fill:before {
  content: "";
}

.bi-chat-heart:before {
  content: "";
}

.bi-chat-left-heart-fill:before {
  content: "";
}

.bi-chat-left-heart:before {
  content: "";
}

.bi-chat-right-heart-fill:before {
  content: "";
}

.bi-chat-right-heart:before {
  content: "";
}

.bi-chat-square-heart-fill:before {
  content: "";
}

.bi-chat-square-heart:before {
  content: "";
}

.bi-clipboard-check-fill:before {
  content: "";
}

.bi-clipboard-data-fill:before {
  content: "";
}

.bi-clipboard-fill:before {
  content: "";
}

.bi-clipboard-heart-fill:before {
  content: "";
}

.bi-clipboard-heart:before {
  content: "";
}

.bi-clipboard-minus-fill:before {
  content: "";
}

.bi-clipboard-plus-fill:before {
  content: "";
}

.bi-clipboard-pulse:before {
  content: "";
}

.bi-clipboard-x-fill:before {
  content: "";
}

.bi-clipboard2-check-fill:before {
  content: "";
}

.bi-clipboard2-check:before {
  content: "";
}

.bi-clipboard2-data-fill:before {
  content: "";
}

.bi-clipboard2-data:before {
  content: "";
}

.bi-clipboard2-fill:before {
  content: "";
}

.bi-clipboard2-heart-fill:before {
  content: "";
}

.bi-clipboard2-heart:before {
  content: "";
}

.bi-clipboard2-minus-fill:before {
  content: "";
}

.bi-clipboard2-minus:before {
  content: "";
}

.bi-clipboard2-plus-fill:before {
  content: "";
}

.bi-clipboard2-plus:before {
  content: "";
}

.bi-clipboard2-pulse-fill:before {
  content: "";
}

.bi-clipboard2-pulse:before {
  content: "";
}

.bi-clipboard2-x-fill:before {
  content: "";
}

.bi-clipboard2-x:before {
  content: "";
}

.bi-clipboard2:before {
  content: "";
}

.bi-emoji-kiss-fill:before {
  content: "";
}

.bi-emoji-kiss:before {
  content: "";
}

.bi-envelope-heart-fill:before {
  content: "";
}

.bi-envelope-heart:before {
  content: "";
}

.bi-envelope-open-heart-fill:before {
  content: "";
}

.bi-envelope-open-heart:before {
  content: "";
}

.bi-envelope-paper-fill:before {
  content: "";
}

.bi-envelope-paper-heart-fill:before {
  content: "";
}

.bi-envelope-paper-heart:before {
  content: "";
}

.bi-envelope-paper:before {
  content: "";
}

.bi-filetype-aac:before {
  content: "";
}

.bi-filetype-ai:before {
  content: "";
}

.bi-filetype-bmp:before {
  content: "";
}

.bi-filetype-cs:before {
  content: "";
}

.bi-filetype-css:before {
  content: "";
}

.bi-filetype-csv:before {
  content: "";
}

.bi-filetype-doc:before {
  content: "";
}

.bi-filetype-docx:before {
  content: "";
}

.bi-filetype-exe:before {
  content: "";
}

.bi-filetype-gif:before {
  content: "";
}

.bi-filetype-heic:before {
  content: "";
}

.bi-filetype-html:before {
  content: "";
}

.bi-filetype-java:before {
  content: "";
}

.bi-filetype-jpg:before {
  content: "";
}

.bi-filetype-js:before {
  content: "";
}

.bi-filetype-jsx:before {
  content: "";
}

.bi-filetype-key:before {
  content: "";
}

.bi-filetype-m4p:before {
  content: "";
}

.bi-filetype-md:before {
  content: "";
}

.bi-filetype-mdx:before {
  content: "";
}

.bi-filetype-mov:before {
  content: "";
}

.bi-filetype-mp3:before {
  content: "";
}

.bi-filetype-mp4:before {
  content: "";
}

.bi-filetype-otf:before {
  content: "";
}

.bi-filetype-pdf:before {
  content: "";
}

.bi-filetype-php:before {
  content: "";
}

.bi-filetype-png:before {
  content: "";
}

.bi-filetype-ppt-1:before {
  content: "";
}

.bi-filetype-ppt:before {
  content: "";
}

.bi-filetype-psd:before {
  content: "";
}

.bi-filetype-py:before {
  content: "";
}

.bi-filetype-raw:before {
  content: "";
}

.bi-filetype-rb:before {
  content: "";
}

.bi-filetype-sass:before {
  content: "";
}

.bi-filetype-scss:before {
  content: "";
}

.bi-filetype-sh:before {
  content: "";
}

.bi-filetype-svg:before {
  content: "";
}

.bi-filetype-tiff:before {
  content: "";
}

.bi-filetype-tsx:before {
  content: "";
}

.bi-filetype-ttf:before {
  content: "";
}

.bi-filetype-txt:before {
  content: "";
}

.bi-filetype-wav:before {
  content: "";
}

.bi-filetype-woff:before {
  content: "";
}

.bi-filetype-xls-1:before {
  content: "";
}

.bi-filetype-xls:before {
  content: "";
}

.bi-filetype-xml:before {
  content: "";
}

.bi-filetype-yml:before {
  content: "";
}

.bi-heart-arrow:before {
  content: "";
}

.bi-heart-pulse-fill:before {
  content: "";
}

.bi-heart-pulse:before {
  content: "";
}

.bi-heartbreak-fill:before {
  content: "";
}

.bi-heartbreak:before {
  content: "";
}

.bi-hearts:before {
  content: "";
}

.bi-hospital-fill:before {
  content: "";
}

.bi-hospital:before {
  content: "";
}

.bi-house-heart-fill:before {
  content: "";
}

.bi-house-heart:before {
  content: "";
}

.bi-incognito:before {
  content: "";
}

.bi-magnet-fill:before {
  content: "";
}

.bi-magnet:before {
  content: "";
}

.bi-person-heart:before {
  content: "";
}

.bi-person-hearts:before {
  content: "";
}

.bi-phone-flip:before {
  content: "";
}

.bi-plugin:before {
  content: "";
}

.bi-postage-fill:before {
  content: "";
}

.bi-postage-heart-fill:before {
  content: "";
}

.bi-postage-heart:before {
  content: "";
}

.bi-postage:before {
  content: "";
}

.bi-postcard-fill:before {
  content: "";
}

.bi-postcard-heart-fill:before {
  content: "";
}

.bi-postcard-heart:before {
  content: "";
}

.bi-postcard:before {
  content: "";
}

.bi-search-heart-fill:before {
  content: "";
}

.bi-search-heart:before {
  content: "";
}

.bi-sliders2-vertical:before {
  content: "";
}

.bi-sliders2:before {
  content: "";
}

.bi-trash3-fill:before {
  content: "";
}

.bi-trash3:before {
  content: "";
}

.bi-valentine:before {
  content: "";
}

.bi-valentine2:before {
  content: "";
}

.bi-wrench-adjustable-circle-fill:before {
  content: "";
}

.bi-wrench-adjustable-circle:before {
  content: "";
}

.bi-wrench-adjustable:before {
  content: "";
}

.bi-filetype-json:before {
  content: "";
}

.bi-filetype-pptx:before {
  content: "";
}

.bi-filetype-xlsx:before {
  content: "";
}

.bi-1-circle-1:before {
  content: "";
}

.bi-1-circle-fill-1:before {
  content: "";
}

.bi-1-circle-fill:before {
  content: "";
}

.bi-1-circle:before {
  content: "";
}

.bi-1-square-fill:before {
  content: "";
}

.bi-1-square:before {
  content: "";
}

.bi-2-circle-1:before {
  content: "";
}

.bi-2-circle-fill-1:before {
  content: "";
}

.bi-2-circle-fill:before {
  content: "";
}

.bi-2-circle:before {
  content: "";
}

.bi-2-square-fill:before {
  content: "";
}

.bi-2-square:before {
  content: "";
}

.bi-3-circle-1:before {
  content: "";
}

.bi-3-circle-fill-1:before {
  content: "";
}

.bi-3-circle-fill:before {
  content: "";
}

.bi-3-circle:before {
  content: "";
}

.bi-3-square-fill:before {
  content: "";
}

.bi-3-square:before {
  content: "";
}

.bi-4-circle-1:before {
  content: "";
}

.bi-4-circle-fill-1:before {
  content: "";
}

.bi-4-circle-fill:before {
  content: "";
}

.bi-4-circle:before {
  content: "";
}

.bi-4-square-fill:before {
  content: "";
}

.bi-4-square:before {
  content: "";
}

.bi-5-circle-1:before {
  content: "";
}

.bi-5-circle-fill-1:before {
  content: "";
}

.bi-5-circle-fill:before {
  content: "";
}

.bi-5-circle:before {
  content: "";
}

.bi-5-square-fill:before {
  content: "";
}

.bi-5-square:before {
  content: "";
}

.bi-6-circle-1:before {
  content: "";
}

.bi-6-circle-fill-1:before {
  content: "";
}

.bi-6-circle-fill:before {
  content: "";
}

.bi-6-circle:before {
  content: "";
}

.bi-6-square-fill:before {
  content: "";
}

.bi-6-square:before {
  content: "";
}

.bi-7-circle-1:before {
  content: "";
}

.bi-7-circle-fill-1:before {
  content: "";
}

.bi-7-circle-fill:before {
  content: "";
}

.bi-7-circle:before {
  content: "";
}

.bi-7-square-fill:before {
  content: "";
}

.bi-7-square:before {
  content: "";
}

.bi-8-circle-1:before {
  content: "";
}

.bi-8-circle-fill-1:before {
  content: "";
}

.bi-8-circle-fill:before {
  content: "";
}

.bi-8-circle:before {
  content: "";
}

.bi-8-square-fill:before {
  content: "";
}

.bi-8-square:before {
  content: "";
}

.bi-9-circle-1:before {
  content: "";
}

.bi-9-circle-fill-1:before {
  content: "";
}

.bi-9-circle-fill:before {
  content: "";
}

.bi-9-circle:before {
  content: "";
}

.bi-9-square-fill:before {
  content: "";
}

.bi-9-square:before {
  content: "";
}

.bi-airplane-engines-fill:before {
  content: "";
}

.bi-airplane-engines:before {
  content: "";
}

.bi-airplane-fill:before {
  content: "";
}

.bi-airplane:before {
  content: "";
}

.bi-alexa:before {
  content: "";
}

.bi-alipay:before {
  content: "";
}

.bi-android:before {
  content: "";
}

.bi-android2:before {
  content: "";
}

.bi-box-fill:before {
  content: "";
}

.bi-box-seam-fill:before {
  content: "";
}

.bi-browser-chrome:before {
  content: "";
}

.bi-browser-edge:before {
  content: "";
}

.bi-browser-firefox:before {
  content: "";
}

.bi-browser-safari:before {
  content: "";
}

.bi-c-circle-1:before {
  content: "";
}

.bi-c-circle-fill-1:before {
  content: "";
}

.bi-c-circle-fill:before {
  content: "";
}

.bi-c-circle:before {
  content: "";
}

.bi-c-square-fill:before {
  content: "";
}

.bi-c-square:before {
  content: "";
}

.bi-capsule-pill:before {
  content: "";
}

.bi-capsule:before {
  content: "";
}

.bi-car-front-fill:before {
  content: "";
}

.bi-car-front:before {
  content: "";
}

.bi-cassette-fill:before {
  content: "";
}

.bi-cassette:before {
  content: "";
}

.bi-cc-circle-1:before {
  content: "";
}

.bi-cc-circle-fill-1:before {
  content: "";
}

.bi-cc-circle-fill:before {
  content: "";
}

.bi-cc-circle:before {
  content: "";
}

.bi-cc-square-fill:before {
  content: "";
}

.bi-cc-square:before {
  content: "";
}

.bi-cup-hot-fill:before {
  content: "";
}

.bi-cup-hot:before {
  content: "";
}

.bi-currency-rupee:before {
  content: "";
}

.bi-dropbox:before {
  content: "";
}

.bi-escape:before {
  content: "";
}

.bi-fast-forward-btn-fill:before {
  content: "";
}

.bi-fast-forward-btn:before {
  content: "";
}

.bi-fast-forward-circle-fill:before {
  content: "";
}

.bi-fast-forward-circle:before {
  content: "";
}

.bi-fast-forward-fill:before {
  content: "";
}

.bi-fast-forward:before {
  content: "";
}

.bi-filetype-sql:before {
  content: "";
}

.bi-fire:before {
  content: "";
}

.bi-google-play:before {
  content: "";
}

.bi-h-circle-1:before {
  content: "";
}

.bi-h-circle-fill-1:before {
  content: "";
}

.bi-h-circle-fill:before {
  content: "";
}

.bi-h-circle:before {
  content: "";
}

.bi-h-square-fill:before {
  content: "";
}

.bi-h-square:before {
  content: "";
}

.bi-indent:before {
  content: "";
}

.bi-lungs-fill:before {
  content: "";
}

.bi-lungs:before {
  content: "";
}

.bi-microsoft-teams:before {
  content: "";
}

.bi-p-circle-1:before {
  content: "";
}

.bi-p-circle-fill-1:before {
  content: "";
}

.bi-p-circle-fill:before {
  content: "";
}

.bi-p-circle:before {
  content: "";
}

.bi-p-square-fill:before {
  content: "";
}

.bi-p-square:before {
  content: "";
}

.bi-pass-fill:before {
  content: "";
}

.bi-pass:before {
  content: "";
}

.bi-prescription:before {
  content: "";
}

.bi-prescription2:before {
  content: "";
}

.bi-r-circle-1:before {
  content: "";
}

.bi-r-circle-fill-1:before {
  content: "";
}

.bi-r-circle-fill:before {
  content: "";
}

.bi-r-circle:before {
  content: "";
}

.bi-r-square-fill:before {
  content: "";
}

.bi-r-square:before {
  content: "";
}

.bi-repeat-1:before {
  content: "";
}

.bi-repeat:before {
  content: "";
}

.bi-rewind-btn-fill:before {
  content: "";
}

.bi-rewind-btn:before {
  content: "";
}

.bi-rewind-circle-fill:before {
  content: "";
}

.bi-rewind-circle:before {
  content: "";
}

.bi-rewind-fill:before {
  content: "";
}

.bi-rewind:before {
  content: "";
}

.bi-train-freight-front-fill:before {
  content: "";
}

.bi-train-freight-front:before {
  content: "";
}

.bi-train-front-fill:before {
  content: "";
}

.bi-train-front:before {
  content: "";
}

.bi-train-lightrail-front-fill:before {
  content: "";
}

.bi-train-lightrail-front:before {
  content: "";
}

.bi-truck-front-fill:before {
  content: "";
}

.bi-truck-front:before {
  content: "";
}

.bi-ubuntu:before {
  content: "";
}

.bi-unindent:before {
  content: "";
}

.bi-unity:before {
  content: "";
}

.bi-universal-access-circle:before {
  content: "";
}

.bi-universal-access:before {
  content: "";
}

.bi-virus:before {
  content: "";
}

.bi-virus2:before {
  content: "";
}

.bi-wechat:before {
  content: "";
}

.bi-yelp:before {
  content: "";
}

.bi-sign-stop-fill:before {
  content: "";
}

.bi-sign-stop-lights-fill:before {
  content: "";
}

.bi-sign-stop-lights:before {
  content: "";
}

.bi-sign-stop:before {
  content: "";
}

.bi-sign-turn-left-fill:before {
  content: "";
}

.bi-sign-turn-left:before {
  content: "";
}

.bi-sign-turn-right-fill:before {
  content: "";
}

.bi-sign-turn-right:before {
  content: "";
}

.bi-sign-turn-slight-left-fill:before {
  content: "";
}

.bi-sign-turn-slight-left:before {
  content: "";
}

.bi-sign-turn-slight-right-fill:before {
  content: "";
}

.bi-sign-turn-slight-right:before {
  content: "";
}

.bi-sign-yield-fill:before {
  content: "";
}

.bi-sign-yield:before {
  content: "";
}

.bi-ev-station-fill:before {
  content: "";
}

.bi-ev-station:before {
  content: "";
}

.bi-fuel-pump-diesel-fill:before {
  content: "";
}

.bi-fuel-pump-diesel:before {
  content: "";
}

.bi-fuel-pump-fill:before {
  content: "";
}

.bi-fuel-pump:before {
  content: "";
}

.bi-0-circle-fill:before {
  content: "";
}

.bi-0-circle:before {
  content: "";
}

.bi-0-square-fill:before {
  content: "";
}

.bi-0-square:before {
  content: "";
}

.bi-rocket-fill:before {
  content: "";
}

.bi-rocket-takeoff-fill:before {
  content: "";
}

.bi-rocket-takeoff:before {
  content: "";
}

.bi-rocket:before {
  content: "";
}

.bi-stripe:before {
  content: "";
}

.bi-subscript:before {
  content: "";
}

.bi-superscript:before {
  content: "";
}

.bi-trello:before {
  content: "";
}

.bi-envelope-at-fill:before {
  content: "";
}

.bi-envelope-at:before {
  content: "";
}

.bi-regex:before {
  content: "";
}

.bi-text-wrap:before {
  content: "";
}

.bi-sign-dead-end-fill:before {
  content: "";
}

.bi-sign-dead-end:before {
  content: "";
}

.bi-sign-do-not-enter-fill:before {
  content: "";
}

.bi-sign-do-not-enter:before {
  content: "";
}

.bi-sign-intersection-fill:before {
  content: "";
}

.bi-sign-intersection-side-fill:before {
  content: "";
}

.bi-sign-intersection-side:before {
  content: "";
}

.bi-sign-intersection-t-fill:before {
  content: "";
}

.bi-sign-intersection-t:before {
  content: "";
}

.bi-sign-intersection-y-fill:before {
  content: "";
}

.bi-sign-intersection-y:before {
  content: "";
}

.bi-sign-intersection:before {
  content: "";
}

.bi-sign-merge-left-fill:before {
  content: "";
}

.bi-sign-merge-left:before {
  content: "";
}

.bi-sign-merge-right-fill:before {
  content: "";
}

.bi-sign-merge-right:before {
  content: "";
}

.bi-sign-no-left-turn-fill:before {
  content: "";
}

.bi-sign-no-left-turn:before {
  content: "";
}

.bi-sign-no-parking-fill:before {
  content: "";
}

.bi-sign-no-parking:before {
  content: "";
}

.bi-sign-no-right-turn-fill:before {
  content: "";
}

.bi-sign-no-right-turn:before {
  content: "";
}

.bi-sign-railroad-fill:before {
  content: "";
}

.bi-sign-railroad:before {
  content: "";
}

.bi-building-add:before {
  content: "";
}

.bi-building-check:before {
  content: "";
}

.bi-building-dash:before {
  content: "";
}

.bi-building-down:before {
  content: "";
}

.bi-building-exclamation:before {
  content: "";
}

.bi-building-fill-add:before {
  content: "";
}

.bi-building-fill-check:before {
  content: "";
}

.bi-building-fill-dash:before {
  content: "";
}

.bi-building-fill-down:before {
  content: "";
}

.bi-building-fill-exclamation:before {
  content: "";
}

.bi-building-fill-gear:before {
  content: "";
}

.bi-building-fill-lock:before {
  content: "";
}

.bi-building-fill-slash:before {
  content: "";
}

.bi-building-fill-up:before {
  content: "";
}

.bi-building-fill-x:before {
  content: "";
}

.bi-building-fill:before {
  content: "";
}

.bi-building-gear:before {
  content: "";
}

.bi-building-lock:before {
  content: "";
}

.bi-building-slash:before {
  content: "";
}

.bi-building-up:before {
  content: "";
}

.bi-building-x:before {
  content: "";
}

.bi-buildings-fill:before {
  content: "";
}

.bi-buildings:before {
  content: "";
}

.bi-bus-front-fill:before {
  content: "";
}

.bi-bus-front:before {
  content: "";
}

.bi-ev-front-fill:before {
  content: "";
}

.bi-ev-front:before {
  content: "";
}

.bi-globe-americas:before {
  content: "";
}

.bi-globe-asia-australia:before {
  content: "";
}

.bi-globe-central-south-asia:before {
  content: "";
}

.bi-globe-europe-africa:before {
  content: "";
}

.bi-house-add-fill:before {
  content: "";
}

.bi-house-add:before {
  content: "";
}

.bi-house-check-fill:before {
  content: "";
}

.bi-house-check:before {
  content: "";
}

.bi-house-dash-fill:before {
  content: "";
}

.bi-house-dash:before {
  content: "";
}

.bi-house-down-fill:before {
  content: "";
}

.bi-house-down:before {
  content: "";
}

.bi-house-exclamation-fill:before {
  content: "";
}

.bi-house-exclamation:before {
  content: "";
}

.bi-house-gear-fill:before {
  content: "";
}

.bi-house-gear:before {
  content: "";
}

.bi-house-lock-fill:before {
  content: "";
}

.bi-house-lock:before {
  content: "";
}

.bi-house-slash-fill:before {
  content: "";
}

.bi-house-slash:before {
  content: "";
}

.bi-house-up-fill:before {
  content: "";
}

.bi-house-up:before {
  content: "";
}

.bi-house-x-fill:before {
  content: "";
}

.bi-house-x:before {
  content: "";
}

.bi-person-add:before {
  content: "";
}

.bi-person-down:before {
  content: "";
}

.bi-person-exclamation:before {
  content: "";
}

.bi-person-fill-add:before {
  content: "";
}

.bi-person-fill-check:before {
  content: "";
}

.bi-person-fill-dash:before {
  content: "";
}

.bi-person-fill-down:before {
  content: "";
}

.bi-person-fill-exclamation:before {
  content: "";
}

.bi-person-fill-gear:before {
  content: "";
}

.bi-person-fill-lock:before {
  content: "";
}

.bi-person-fill-slash:before {
  content: "";
}

.bi-person-fill-up:before {
  content: "";
}

.bi-person-fill-x:before {
  content: "";
}

.bi-person-gear:before {
  content: "";
}

.bi-person-lock:before {
  content: "";
}

.bi-person-slash:before {
  content: "";
}

.bi-person-up:before {
  content: "";
}

.bi-scooter:before {
  content: "";
}

.bi-taxi-front-fill:before {
  content: "";
}

.bi-taxi-front:before {
  content: "";
}

.bi-amd:before {
  content: "";
}

.bi-database-add:before {
  content: "";
}

.bi-database-check:before {
  content: "";
}

.bi-database-dash:before {
  content: "";
}

.bi-database-down:before {
  content: "";
}

.bi-database-exclamation:before {
  content: "";
}

.bi-database-fill-add:before {
  content: "";
}

.bi-database-fill-check:before {
  content: "";
}

.bi-database-fill-dash:before {
  content: "";
}

.bi-database-fill-down:before {
  content: "";
}

.bi-database-fill-exclamation:before {
  content: "";
}

.bi-database-fill-gear:before {
  content: "";
}

.bi-database-fill-lock:before {
  content: "";
}

.bi-database-fill-slash:before {
  content: "";
}

.bi-database-fill-up:before {
  content: "";
}

.bi-database-fill-x:before {
  content: "";
}

.bi-database-fill:before {
  content: "";
}

.bi-database-gear:before {
  content: "";
}

.bi-database-lock:before {
  content: "";
}

.bi-database-slash:before {
  content: "";
}

.bi-database-up:before {
  content: "";
}

.bi-database-x:before {
  content: "";
}

.bi-database:before {
  content: "";
}

.bi-houses-fill:before {
  content: "";
}

.bi-houses:before {
  content: "";
}

.bi-nvidia:before {
  content: "";
}

.bi-person-vcard-fill:before {
  content: "";
}

.bi-person-vcard:before {
  content: "";
}

.bi-sina-weibo:before {
  content: "";
}

.bi-tencent-qq:before {
  content: "";
}

.bi-wikipedia:before {
  content: "";
}

/*# sourceMappingURL=index.b1a5f6df.css.map */
